import React, {useEffect} from 'react'
import GraphDifference from '../OAD/Graphiques/graphDifference'
import TitreRapport from './titre_rapport'
import apiLink from '../../config'

function Page203020504D({idExploit}) {
    const [R2020, setR2020] = React.useState()
    const [R2025, setR2025] = React.useState()
    const [R2030, setR2030] = React.useState()
    const [R2035, setR2035] = React.useState()
    const [R2040, setR2040] = React.useState()
    const [R2045, setR2045] = React.useState()
    const [R2050, setR2050] = React.useState()
    const [Moy, setRMoy] = React.useState()
    const [PB, setPB] = React.useState(0)
    const [surf, setSurf] = React.useState(0)

    function fetchData() {
      fetch(apiLink + 'page/optimisation/' + idExploit)
        .then( (response) => {
          return response.json()
        })
        .then(response =>{
            let MSF = (parseFloat(response.dataGraphCN['Sans Assurance'][1]*100)).toFixed(1)
            let COSF = (parseFloat(response.risques7)).toFixed(1)
            fetch('https://api2050.diagorisk.com/resultatExploitation/?idExploit=' + idExploit)
            .then((response) => response.json())
            .then((response) => {
              let difMoy = response.RMoy_4degre[0]-MSF
              let difCo = 1.25*(response.R2020_4degre[1]-COSF)
              let RefCo = response.R2020_4degre[1]
              if ('error' in response) {
                // Si la réponse contient une erreur, relancer la requête après 60 secondes
                setTimeout(fetchData, 60000);
              } else {
                // Si la réponse est réussie, mettre à jour les données
                setR2020(RefCo>COSF ? [response.R2020_4degre[0]-difCo,response.R2020_4degre[1]-difCo,response.R2020_4degre[2]-difCo,response.R2020_4degre[3]-difCo,response.R2020_4degre[4]-difCo,response.R2020_4degre[5]-difCo,response.R2020_4degre[6]-difCo,] : response.R2020_4degre)
                setR2020(prev => {
                  return prev;
                })
                setR2025(RefCo>COSF ? [response.R2025_4degre[0]-difCo,response.R2025_4degre[1]-difCo,response.R2025_4degre[2]-difCo,response.R2025_4degre[3]-difCo,response.R2025_4degre[4]-difCo,response.R2025_4degre[5]-difCo,response.R2025_4degre[6]-difCo,] : response.R2025_4degre)
                setR2025(prev => {
                  return prev;
                })
                setR2030(RefCo>COSF ? [response.R2030_4degre[0]-difCo,response.R2030_4degre[1]-difCo,response.R2030_4degre[2]-difCo,response.R2030_4degre[3]-difCo,response.R2030_4degre[4]-difCo,response.R2030_4degre[5]-difCo,response.R2030_4degre[6]-difCo,] : response.R2030_4degre)
                setR2030(prev => {
                  return prev;
                })
                setR2035(RefCo>COSF ? [response.R2035_4degre[0]-difCo,response.R2035_4degre[1]-difCo,response.R2035_4degre[2]-difCo,response.R2035_4degre[3]-difCo,response.R2035_4degre[4]-difCo,response.R2035_4degre[5]-difCo,response.R2035_4degre[6]-difCo,] : response.R2035_4degre)
                setR2035(prev => {
                  return prev;
                })
                setR2040(RefCo>COSF ? [response.R2040_4degre[0]-difCo,response.R2040_4degre[1]-difCo,response.R2040_4degre[2]-difCo,response.R2040_4degre[3]-difCo,response.R2040_4degre[4]-difCo,response.R2040_4degre[5]-difCo,response.R2040_4degre[6]-difCo,] : response.R2040_4degre)
                setR2040(prev => {
                  return prev;
                })
                setR2045(RefCo>COSF ? [response.R2045_4degre[0]-difCo,response.R2045_4degre[1]-difCo,response.R2045_4degre[2]-difCo,response.R2045_4degre[3]-difCo,response.R2045_4degre[4]-difCo,response.R2045_4degre[5]-difCo,response.R2045_4degre[6]-difCo,] : response.R2045_4degre)
                setR2045(prev => {
                  return prev;
                })
                setR2050(RefCo>COSF ? [response.R2050_4degre[0]-difCo,response.R2050_4degre[1]-difCo,response.R2050_4degre[2]-difCo,response.R2050_4degre[3]-difCo,response.R2050_4degre[4]-difCo,response.R2050_4degre[5]-difCo,response.R2050_4degre[6]-difCo,] : response.R2050_4degre)
                setR2050(prev => {
                  return prev;
                })
                setRMoy([response.RMoy_4degre[0]-difMoy,response.RMoy_4degre[1]-difMoy, response.RMoy_4degre[2]-difMoy,response.RMoy_4degre[3]-difMoy,response.RMoy_4degre[4]-difMoy,response.RMoy_4degre[5]-difMoy,response.RMoy_4degre[6]-difMoy,])
                setRMoy(prev => {
                  return prev;
                })
              }
            })
          }) 
        /* fetch('https://api2050.diagorisk.com/resultatExploitation/?idExploit=' + idExploit)
          .then((response) => response.json())
          .then((response) => {
            if ('error' in response) {
              // Si la réponse contient une erreur, relancer la requête après 60 secondes
              setTimeout(fetchData, 60000);
            } else {
              setR2020(response.R2020_4degre)
              setR2020(prev => {
                return prev;
              })
              setR2025(response.R2025_4degre)
              setR2025(prev => {
                return prev;
              })
              setR2030(response.R2030_4degre)
              setR2030(prev => {
                return prev;
              })
              setR2035(response.R2035_4degre)
              setR2035(prev => {
                return prev;
              })
              setR2040(response.R2040_4degre)
              setR2040(prev => {
                return prev;
              })
              setR2045(response.R2045_4degre)
              setR2045(prev => {
                return prev;
              })
              setR2050(response.R2050_4degre)
              setR2050(prev => {
                return prev;
              })
              setRMoy(response.RMoy_4degre)
              setRMoy(prev => {
                return prev;
              })
              setSurf(calculateSum(response.dataSurface))
              setSurf(prev => {
                return prev;
              })
              setPB(calculateSum(response.dataPB))
              setPB(prev => {
                return prev;
              })
            }
          })
          .catch((error) => {
            // Gérer les erreurs de la requête
            console.error('Erreur lors de la requête API :', error);
          }); */
      }
      useEffect ( () => {
        fetchData()
      }, [idExploit])

      const calculateSum = (dataSurface) => {
        if (dataSurface) {
          return dataSurface.reduce((sum, item) => sum + item.value, 0);
        }
        return 0;
      };
  
  return (
    <div className='containerPrint'>
      <TitreRapport Titre={'Impact du réchauffement à +4°C de 2020 à 2050'} Sous_Titre={"Sur toute l'exploitation"} Page={'5'}/>
      <div className='paragraphe'> Vous retrouvez dans le tableau les variations de risque de perte de produit brut entre 2020 et l'année choisie (pour 2050, la variation est celle de 2050 moins celle de 2020). </div>
      <GraphDifference myData2020={R2020} myData2={R2050} annee={'2050'} degre={4}print={true}/> 
      {R2020!== undefined && R2030!== undefined && R2040!== undefined && R2050!== undefined && Moy!== undefined ? (
        <div>
        <div className='titreMoins' style={{textAlign:'center', marginTop:'00px'}}> Evolution du coût du risque en % de produit brut en point </div>
        <div className='tableau_prix_rapport' style={{width:'97%', marginBottom:'5px'}}> 
            <div className='row_tableau_prix_rapport'>
                <div className='ordonnée_tableau_prix_rapport ordonne_2050' style={{height:'60px', fontWeight:'bold', textAlign:'center', paddingLeft:'2.5px', paddingRight:'2.5px'}}>Par rapport à 2020 </div>
                <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tableau_prix_rapport' style={{backgroundColor:'#7030A2', height:'60px', textAlign:'center'}}> Très courant <br/>(3 ans)</div>
                <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tableau_prix_rapport' style={{backgroundColor:'#FABE06', height:'60px', textAlign:'center'}}> Courant <br/>(7 ans) </div>
                <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tableau_prix_rapport'style={{backgroundColor:'#0027D6', height:'60px', textAlign:'center'}}> Exceptionnel <br/>(20 ans)</div>
                <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tableau_prix_rapport' style={{backgroundColor:'#DB04B9', height:'60px', textAlign:'center'}}> Catastrophique <br/>(50 ans) </div>
                <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tableau_prix_rapport' style={{backgroundColor:'white', color:'black', height:'60px', textAlign:'center'}}> Moyen</div>
            </div>
            <div className='row_tableau_prix_rapport'>
                <div className='ordonnée_tableau_prix_rapport ordonne_2050' style={{fontWeight:'bold'}}> 2025 </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2025[0]- R2020[0]).toFixed(1).toLocaleString()} % </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2025[1]- R2020[1]).toFixed(1).toLocaleString()} % </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2025[2]- R2020[2]).toFixed(1).toLocaleString()} % </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2025[3]- R2020[3]).toFixed(1).toLocaleString()} % </div>
                <div className='data_tableau_prix_rapport data_tableau_2050' style={{fontWeight:'bold'}}> { (Moy[1]- Moy[0]).toFixed(1).toLocaleString()} % </div>
            </div>
            <div className='row_tableau_prix_rapport'>
                <div className='ordonnée_tableau_prix_rapport ordonne_2050' style={{fontWeight:'bold'}}> 2030 </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2030[0]- R2020[0]).toFixed(1).toLocaleString()} % </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2030[1]- R2020[1]).toFixed(1).toLocaleString()} % </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2030[2]- R2020[2]).toFixed(1).toLocaleString()} % </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2030[3]- R2020[3]).toFixed(1).toLocaleString()} % </div>
                <div className='data_tableau_prix_rapport data_tableau_2050' style={{fontWeight:'bold'}}> { (Moy[2]- Moy[0]).toFixed(1).toLocaleString()} % </div>
            </div>
            <div className='row_tableau_prix_rapport'>
                <div className='ordonnée_tableau_prix_rapport ordonne_2050' style={{fontWeight:'bold'}}> 2035 </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2035[0]- R2020[0]).toFixed(1).toLocaleString()} % </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2035[1]- R2020[1]).toFixed(1).toLocaleString()} % </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2035[2]- R2020[2]).toFixed(1).toLocaleString()} % </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2035[3]- R2020[3]).toFixed(1).toLocaleString()} % </div>
                <div className='data_tableau_prix_rapport data_tableau_2050' style={{fontWeight:'bold'}}> { (Moy[3]- Moy[0]).toFixed(1).toLocaleString()} % </div>
            </div>
            <div className='row_tableau_prix_rapport'>
                <div className='ordonnée_tableau_prix_rapport ordonne_2050' style={{fontWeight:'bold'}}> 2040 </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2040[0]- R2020[0]).toFixed(1).toLocaleString()} % </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2040[1]- R2020[1]).toFixed(1).toLocaleString()} % </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2040[2]- R2020[2]).toFixed(1).toLocaleString()} % </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2040[3]- R2020[3]).toFixed(1).toLocaleString()} % </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'style={{fontWeight:'bold'}}> { (Moy[4]- Moy[0]).toFixed(1).toLocaleString()} % </div>
            </div>
            <div className='row_tableau_prix_rapport'>
                <div className='ordonnée_tableau_prix_rapport ordonne_2050' style={{fontWeight:'bold'}}> 2045 </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2045[0]- R2020[0]).toFixed(1).toLocaleString()} % </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2045[1]- R2020[1]).toFixed(1).toLocaleString()} % </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2045[2]- R2020[2]).toFixed(1).toLocaleString()} % </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2045[3]- R2020[3]).toFixed(1).toLocaleString()} % </div>
                <div className='data_tableau_prix_rapport data_tableau_2050' style={{fontWeight:'bold'}}> { (Moy[5]- Moy[0]).toFixed(1).toLocaleString()} % </div>
            </div>
            <div className='row_tableau_prix_rapport'>
            <div className='ordonnée_tableau_prix_rapport ordonne_2050' style={{fontWeight:'bold'}}> 2050 </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2050[0]- R2020[0]).toFixed(1).toLocaleString()}% </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2050[1]- R2020[1]).toFixed(1).toLocaleString()} % </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2050[2]- R2020[2]).toFixed(1).toLocaleString()} % </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2050[3]- R2020[3]).toFixed(1).toLocaleString()} % </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'style={{fontWeight:'bold'}}> { (Moy[6]- Moy[0]).toFixed(1).toLocaleString()} % </div>
            </div>
        </div>

        {/* <div className='titreMoins espaceprint' style= {{marginTop:'10px',textAlign:'center'}}>Evolution du coût du risque en euros par hectare </div> 
        <div className='tableau_prix_rapport' style={{width:'97%', marginTop:'10px'}}> 
            <div className='row_tableau_prix_rapport'>
                <div className='ordonnée_tableau_prix_rapport ordonne_2050' style={{height:'60px', fontWeight:'bold', textAlign:'center', paddingLeft:'2.5px', paddingRight:'2.5px'}}>Par rapport à 2020 </div>
                <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tableau_prix_rapport' style={{backgroundColor:'#7030A2', height:'60px', textAlign:'center'}}> Très courant <br/>(3 ans)</div>
                <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tableau_prix_rapport' style={{backgroundColor:'#FABE06', height:'60px', textAlign:'center'}}> Courant <br/>(7 ans) </div>
                <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tableau_prix_rapport'style={{backgroundColor:'#0027D6', height:'60px', textAlign:'center'}}> Exceptionnel <br/>(20 ans)</div>
                <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tableau_prix_rapport' style={{backgroundColor:'#DB04B9', height:'60px', textAlign:'center'}}> Catastrophique <br/>(50 ans) </div>
                <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tableau_prix_rapport' style={{backgroundColor:'white', color:'black', height:'60px', textAlign:'center'}}> Moyen</div>
            </div>
            <div className='row_tableau_prix_rapport'>
                <div className='ordonnée_tableau_prix_rapport ordonne_2050' style={{fontWeight:'bold'}}> 2025 </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2025[0]*PB/100- R2020[0]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2025[1]*PB/100- R2020[1]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2025[2]*PB/100- R2020[2]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2025[3]*PB/100- R2020[3]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'style={{fontWeight:'bold'}}> { (Math.round(Moy[1]*PB/100- Moy[0]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
            </div>
            <div className='row_tableau_prix_rapport'>
                <div className='ordonnée_tableau_prix_rapport ordonne_2050' style={{fontWeight:'bold'}}> 2030 </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2030[0]*PB/100- R2020[0]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2030[1]*PB/100- R2020[1]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2030[2]*PB/100- R2020[2]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2030[3]*PB/100- R2020[3]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'style={{fontWeight:'bold'}}> { (Math.round(Moy[2]*PB/100- Moy[0]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
            </div>
            <div className='row_tableau_prix_rapport'>
                <div className='ordonnée_tableau_prix_rapport ordonne_2050' style={{fontWeight:'bold'}}> 2035 </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2035[0]*PB/100- R2020[0]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2035[1]*PB/100- R2020[1]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2035[2]*PB/100- R2020[2]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2035[3]*PB/100- R2020[3]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'style={{fontWeight:'bold'}}> { (Math.round(Moy[3]*PB/100- Moy[0]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
            </div>
            <div className='row_tableau_prix_rapport'>
                <div className='ordonnée_tableau_prix_rapport ordonne_2050' style={{fontWeight:'bold'}}> 2040 </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2040[0]*PB/100- R2020[0]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2040[1]*PB/100- R2020[1]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2040[2]*PB/100- R2020[2]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2040[3]*PB/100- R2020[3]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'style={{fontWeight:'bold'}}> { (Math.round(Moy[4]*PB/100- Moy[0]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
            </div>
            <div className='row_tableau_prix_rapport'>
                <div className='ordonnée_tableau_prix_rapport ordonne_2050' style={{fontWeight:'bold'}}> 2045 </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2045[0]*PB/100- R2020[0]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2045[1]*PB/100- R2020[1]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2045[2]*PB/100- R2020[2]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2045[3]*PB/100- R2020[3]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'style={{fontWeight:'bold'}}> { (Math.round(Moy[5]*PB/100- Moy[0]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
            </div>
            <div className='row_tableau_prix_rapport'>
                <div className='ordonnée_tableau_prix_rapport ordonne_2050' style={{fontWeight:'bold'}}> 2050 </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2050[0]*PB/100- R2020[0]*PB/100)/surf).toFixed(0).toLocaleString()}€ </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2050[1]*PB/100- R2020[1]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2050[2]*PB/100- R2020[2]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2050[3]*PB/100- R2020[3]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                <div className='data_tableau_prix_rapport data_tableau_2050'style={{fontWeight:'bold'}}> { (Math.round(Moy[6]*PB/100- Moy[0]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
            </div>
        </div> */}
        </div>
        ) : ("")}
      { Moy!== undefined ? (
      <div className='commentaireRapport' style={{marginTop:'50px'}}>
        <div style={{fontWeight:'bold', marginBottom:'5px'}}>Commentaire : </div>
        <div className='paragraphe'>La perte moyenne de rendement varie de {Moy[0].toFixed(1)}% en 2020 à : </div>
        <div className='paragraphe' style={{marginLeft:'15px'}}> - {Moy[1].toFixed(1)}% en 2025. La perte en 2025 est donc {(Moy[1]- Moy[0]).toFixed(1) > 0 ? "plus": "moins"} importante de {Math.abs(Moy[1]- Moy[0]).toFixed(1)} %</div>
        <div className='paragraphe' style={{marginLeft:'15px'}}> - {Moy[2].toFixed(1)}% en 2030. La perte en 2030 est donc {(Moy[2]- Moy[0]).toFixed(1) > 0 ? "plus": "moins"} importante de {Math.abs(Moy[2]- Moy[0]).toFixed(1)} %</div>
        <div className='paragraphe' style={{marginLeft:'15px'}}> - {Moy[3].toFixed(1)}% en 2035. La perte en 2035 est donc {(Moy[3]- Moy[0]).toFixed(1) > 0 ? "plus": "moins"} importante de {Math.abs(Moy[3]- Moy[0]).toFixed(1)} %</div>
        <div className='paragraphe' style={{marginLeft:'15px'}}> - {Moy[4].toFixed(1)}% en 2040. La perte en 2040 est donc {(Moy[4]- Moy[0]).toFixed(1) > 0 ? "plus": "moins"} importante de {Math.abs(Moy[4]- Moy[0]).toFixed(1)} %</div>
        <div className='paragraphe' style={{marginLeft:'15px'}}> - {Moy[5].toFixed(1)}% en 2045. La perte en 2045 est donc {(Moy[5]- Moy[0]).toFixed(1) > 0 ? "plus": "moins"} importante de {Math.abs(Moy[5]- Moy[0]).toFixed(1)} %</div>
        <div className='paragraphe' style={{marginLeft:'15px'}}> - {Moy[6].toFixed(1)}% en 2050. La perte en 2050 est donc {(Moy[6]- Moy[0]).toFixed(1) > 0 ? "plus": "moins"} importante de {Math.abs(Moy[6]- Moy[0]).toFixed(1)} %</div>
      </div>
      ) : ("")}
    </div>
  )
}

export default Page203020504D
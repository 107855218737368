import React from "react";
import ReactEcharts from "echarts-for-react"; 
function GraphRiskMoy({Data2D, Data4D, DataSF}) { 
    const min2 = Math.min(...Data2D)
    const min4 = Math.min(...Data4D)
    const mintot = min2 < min4 ? min2 : min4
    const min = Math.round(mintot <3 ? 0 : ((mintot - 3) / 10) * 10)
    const getOptions = () => {
        let options = {
          legend: {
              data: ['Risques Moyens +2°C', 'Risques Moyens +4°C'],
              textStyle: {
                fontSize: 16, // Ajustez la taille de la police de la légende selon vos besoins
              },
          },
          xAxis: {
            type: 'category',
            data: ['2020', '2025', '2030', '2035', '2040','2045', '2050'],
            name: 'Risques',
            nameTextStyle: {
              fontSize: 16,
            },
            axisLabel: {
              fontSize: 16
            },
          },
          yAxis: {
            type: 'value',
            name: 'Pertes en %',
            min:min,
            nameTextStyle: {
              fontSize: 16,
            },
            axisLabel: {
              fontSize: 16,
            },
          },
          series: [
            {
              name: 'Risques Moyens +2°C',
              data: Data2D,
              type: 'bar',
              stack: 'stack1',
              barGap: 0,
              itemStyle: {
                color: '#B0A480',
              },
              emphasis: {
                focus: 'series',
                label: {
                  show: true,
                  formatter: function (param) {
                    return (param.data).toFixed(2) + " %" ;
                  },
                  position:'right',
                  textStyle: {
                    fontSize: 14 // Ajustez la taille de police selon vos préférences
                  },
                },
              },
            },
            {
              name: 'Risques Moyens +4°C',
              data: Data4D,
              type: 'bar',
              stack: 'stack2',
              barGap: 0,
              itemStyle: {
                color: '#711526',
              },
              emphasis: {
                focus: 'series',
                label: {
                  show: true,
                  formatter: function (param) {
                    return (param.data).toFixed(2) + " %" ;
                  },
                  position:'right',
                  textStyle: {
                    fontSize: 14 // Ajustez la taille de police selon vos préférences
                  },
                },
              },
            },
            {
              name: 'Différence SimFerme',
              data: DataSF,
              type: 'bar',
              stack: 'stack1',
              barGap: 0,
              itemStyle: {
                color: 'red',
              },
              emphasis: {
                focus: 'series',
                label: {
                  show: true,
                  formatter: function (param) {
                    return param.data + " %" ;
                  },
                  position:'right',
                  textStyle: {
                    fontSize: 14 // Ajustez la taille de police selon vos préférences
                  },
                },
              },
            },
            
          ],
        };
      
        return options;
      };
      
  

    return (
      <div style={{marginBottom:"20px", marginTop:'20px', width: '100%', height: '450px'}}> {console.log(DataSF)}
        <ReactEcharts option={getOptions()} style={{ height: '100%', width:'100%' }} />
    </div>
  
  );
}

export default GraphRiskMoy;
  
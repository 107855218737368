import React from "react";
import {
    XYPlot,
    YAxis,
    HorizontalGridLines,
    AreaSeries,
    LineSeries,
    makeVisFlexible,
  } from "react-vis";

function Graph3Vagues({dataPerso, dataVagues7, dataVagues20, dataVagues50, maximum, dataQ1, dataQ2, dataQ3, GC, print}) {
    const yDomain= GC ? [0,80] : [0,100]
    const xDomain= GC ?[1,520] : ""
    const FlexXYPlot=makeVisFlexible(XYPlot)
    var hauteurFenetre = window.innerHeight;

    return (
      <div> 
        <FlexXYPlot  height={hauteurFenetre < 600 ? 375 : 450}  {...(print ? { width: 920 } : {})} {...{ yDomain}} {...{ xDomain}} >
            <YAxis 
            title="%PB"
            style={{
                text: {stroke: 'none', fill: 'black', fontWeight: 500, fontSize:20},
                title:{marginRight:'10px'}
            }}
            tickTotal={9}/>
            <AreaSeries
                className="area-series-example"
                color="#DB04B9"
                data={dataVagues50}
            />
            <AreaSeries
                className="area-series-example"
                color="#0027CC"
                data={dataVagues20}
            />
            <AreaSeries
                className="area-series-example"
                color="#FFC000"
                data={dataVagues7}
            />

            <HorizontalGridLines  style={{stroke: '#D3D3D3'}}  />

            <LineSeries
                className="Q1"
                style={{stroke: 'black', strokeWidth:4, fill:'transparent' }}
                data={dataQ1}
            />
            <LineSeries
                className="Q2"
                style={{stroke: 'black', strokeWidth:4, fill:'transparent' }}
                data={dataQ2}
            />
            <LineSeries
                className="Q3"
                style={{stroke: 'black', strokeWidth:4, fill:'transparent' }}
                data={dataQ3}
            />
            <LineSeries
                className="Perso"
                style={{stroke: 'red', strokeWidth:7, fill:'transparent' }}
                data={dataPerso}
                strokeDasharray="8, 4"
            />
            
        </FlexXYPlot>
      </div>
  
    );
  }
  
  export default Graph3Vagues;
  
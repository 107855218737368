import React from 'react';
import legend from '../../../assets/legendes-cartes-2050.jpg'
import legendIrrig from '../../../assets/legendes-cartes-2050-irrig.jpg'

function LegendeCarteRisk2050(props) {

  return ( props.trigger) ? (
    <div className='popupLegend2050'>
        <div className='popup-inner'>
            <button className='close-btn2050' onClick={() => props.setTrigger(false)}>X</button>
            <img src={props.irrig ? legendIrrig : legend} alt="legend carte" className="legendCarte_RiskRDT2050"/>
            {props.children}
        </div>

    </div>
  ) : "" ;
}

export default LegendeCarteRisk2050;

import React, {useState, useEffect} from 'react'
import apiLink from '../../../config.js'
import axios from "axios";

import ButtonQuestions from '../../MEP/button question.js';
import Entete from '../../MEP/entete';
import Culture from './culture_assol';
import CultureAjoutSpe from './culture_assol_ajout_spe';
import CultureAjoutCepage from './culture_assol_ajout_cepage.js';
import LocalisationIns from '../../Loc_et_assol/localisation_inscription'
import {Navigate} from 'react-router-dom'

function NewSimulation() {
  const [goToExploit, setGoToExploit] = useState(false)
  const [goToHome, setGoToHome] = React.useState(false)
  const [idExploit, setidExploit] = useState()
  const [showSemis, setShowSemis] = React.useState(false)
  const [city, setCity] = useState()
  const [Name, setName] = useState()
  const [TypeCulture, setTypeCulture] = useState("Conventionnelle")
  const [valueSpeaddOtherSpe, setValueSpeAdddOtherSpe]= useState()
  const [dataPlusCult, setdataPlusCult]= useState({})
  const mesDonnees = new FormData(); 
  const mesDonneesP = new FormData(); 
  const idAgent = JSON.parse(localStorage.getItem('idAgent'));
  const idExploitationPrincipale = JSON.parse(localStorage.getItem('idExploitationPrinicpale'));
  const [dataDefaut, setDataDefaut] = useState()
  const [Cepage, setDataCepage] = useState({
    "Cepage": {
      "SelectedSpe" : "none",
      "Semis" : "",
      "libelle" : "Cépage",
      "prix" : 173.0,
      "prixBio" : null,
      "prixConv" : null,
      "rendement" : 90.3,
      "rendementBio" : 80.5,
      "rendementConv" : 90.3,
      "rendementIrrig" : 95.4,
      "surface" : "0.00",
      "Specification" : {
        "1":{
          "key" : "1",
          "libelle" : "Gamay N",
          "rendement" : 90.3,
          "rendementBio" : 80.5,
          "rendementIrrig" : 95.4,
          "prix" : 173.0,
          "prixBio" : null,
          "prixConv" : null,
          "surface" : 0
        },
        "2":{
          "key" : "2",
          "libelle" : "Chardonnay B",
          "rendement" : 90.3,
          "rendementBio" : 80.5,
          "rendementIrrig" : 95.4,
          "prix" : 173.0,
          "prixBio" : null,
          "prixConv" : null,
          "surface" : 0
        },
        "3":{
          "key" : "3",
          "libelle" : "Pinot Noir N",
          "rendement" : 90.3,
          "rendementBio" : 80.5,
          "rendementIrrig" : 95.4,
          "prix" : 173.0,
          "prixBio" : null,
          "prixConv" : null,
          "surface" : 0
        },
        "4":{
          "key" : "4",
          "libelle" : "Chasselas B",
          "rendement" : 90.3,
          "rendementBio" : 80.5,
          "rendementIrrig" : 95.4,
          "prix" : 173.0,
          "prixBio" : null,
          "prixConv" : null,
          "surface" : 0
        },
        "5":{
          "key" : "5",
          "libelle" : "Sauvignon B",
          "rendement" : 90.3,
          "rendementBio" : 80.5,
          "rendementIrrig" : 95.4,
          "prix" : 173.0,
          "prixBio" : null,
          "prixConv" : null,
          "surface" : 0
        },
        "6":{
          "key" : "6",
          "libelle" : "Carignan N",
          "rendement" : 90.3,
          "rendementBio" : 80.5,
          "rendementIrrig" : 95.4,
          "prix" : 173.0,
          "prixBio" : null,
          "prixConv" : null,
          "surface" : 0
        },
        "7":{
          "key" : "7",
          "libelle" : "Grenache N",
          "rendement" : 90.3,
          "rendementBio" : 80.5,
          "rendementIrrig" : 95.4,
          "prix" : 173.0,
          "prixBio" : null,
          "prixConv" : null,
          "surface" : 0
        },
        "8":{
          "key" : "8",
          "libelle" : "Syrah N",
          "rendement" : 90.3,
          "rendementBio" : 80.5,
          "rendementIrrig" : 95.4,
          "prix" : 173.0,
          "prixBio" : null,
          "prixConv" : null,
          "surface" : 0
        },
        "9":{
          "key" : "9",
          "libelle" : "Ugni Blanc B",
          "rendement" : 90.3,
          "rendementBio" : 80.5,
          "rendementIrrig" : 95.4,
          "prix" : 173.0,
          "prixBio" : null,
          "prixConv" : null,
          "surface" : 0
        },
        "10":{
          "key" : "10",
          "libelle" : "Cabernet Sauvignon N",
          "rendement" : 90.3,
          "rendementBio" : 80.5,
          "rendementIrrig" : 95.4,
          "prix" : 173.0,
          "prixBio" : null,
          "prixConv" : null,
          "surface" : 0
        },
        "11":{
          "key" : "11",
          "libelle" : "Cinsault N",
          "rendement" : 90.3,
          "rendementBio" : 80.5,
          "rendementIrrig" : 95.4,
          "prix" : 173.0,
          "prixBio" : null,
          "prixConv" : null,
          "surface" : 0
        },
        "12":{
          "key" : "12",
          "libelle" : "Merlot N",
          "rendement" : 90.3,
          "rendementBio" : 80.5,
          "rendementIrrig" : 95.4,
          "prix" : 173.0,
          "prixBio" : null,
          "prixConv" : null,
          "surface" : 0
        },
        "13":{
          "key" : "13",
          "libelle" : "Cabernet Franc N",
          "rendement" : 90.3,
          "rendementBio" : 80.5,
          "rendementIrrig" : 95.4,
          "prix" : 173.0,
          "prixBio" : null,
          "prixConv" : null,
          "surface" : 0
        },
        "14":{
          "key" : "14",
          "libelle" : "Mourvedre N",
          "rendement" : 90.3,
          "rendementBio" : 80.5,
          "rendementIrrig" : 95.4,
          "prix" : 173.0,
          "prixBio" : null,
          "prixConv" : null,
          "surface" : 0
        },
        "15":{
          "key" : "15",
          "libelle" : "Tannat N",
          "rendement" : 90.3,
          "rendementBio" : 80.5,
          "rendementIrrig" : 95.4,
          "prix" : 173.0,
          "prixBio" : null,
          "prixConv" : null,
          "surface" : 0
        },
        "16":{
          "key" : "16",
          "libelle" : "Cot N",
          "rendement" : 90.3,
          "rendementBio" : 80.5,
          "rendementIrrig" : 95.4,
          "prix" : 173.0,
          "prixBio" : null,
          "prixConv" : null,
          "surface" : 0
        },
        "17":{
          "key" : "17",
          "libelle" : "Petit Verdot N",
          "rendement" : 90.3,
          "rendementBio" : 80.5,
          "rendementIrrig" : 95.4,
          "prix" : 173.0,
          "prixBio" : null,
          "prixConv" : null,
          "surface" : 0
        },
        "18":{
          "key" : "18",
          "libelle" : "Riesling B",
          "rendement" : 90.3,
          "rendementBio" : 80.5,
          "rendementIrrig" : 95.4,
          "prix" : 173.0,
          "prixBio" : null,
          "prixConv" : null,
          "surface" : 0
        }
      }
    }
  })
  const [dataPlusCultCepage, setdataPlusCultCepage]= useState({})
  const [dataExploit, setDataExploit] = useState()
  const [data,setData]= useState({})

  const CreaDataDupliquee = (dataExploit, dataDefaut) => {
    const data = {};
    if (dataDefaut !== undefined) {
      let keyCount = 1;
      for (const exploitKey in dataExploit) {
        if (dataExploit.hasOwnProperty(exploitKey)) {
          const exploitObj = dataExploit[exploitKey];
          const exploitKeys = Object.keys(exploitObj);
  
          if (dataDefaut.hasOwnProperty(exploitKey)) {
            const defautObj = dataDefaut[exploitKey];
            const defautEntry = dataDefaut[exploitKey]
            const defautKeys = Object.keys(defautObj);
  
            for (let i = 1; i < exploitKeys.length; i++) {
              const exploitEntry = exploitObj[exploitKeys[i]];
  
              if (i < defautKeys.length) {  
                data[keyCount] = {
                  libelle: exploitEntry.libelle,
                  SelectedSpe: exploitEntry.SelectedSpe,
                  surface: exploitEntry.surface,
                  rendement: exploitEntry.rendement,
                  rendementConv: defautEntry.rendement,
                  rendementBio: defautEntry.rendementBio,
                  rendementIrrig: defautEntry.rendementIrrig,
                  prix: exploitEntry.prix,
                  prixConv: defautEntry.prix,
                  prixBio: defautEntry.prixBio,
                  Specification: defautEntry.specifications,
                  key: exploitKey,
                };
  
                keyCount++;
              } else {
                data[keyCount] = {
                  libelle: exploitEntry.libelle,
                  SelectedSpe: exploitEntry.SelectedSpe,
                  surface: exploitEntry.surface,
                  rendement: exploitEntry.rendement,
                  rendementConv: null,
                  rendementBio: null,
                  rendementIrrig: null,
                  prix: exploitEntry.prix,
                  prixConv: null,
                  prixBio: null,
                  Specification: null,
                  key: exploitKey,
                };
  
                keyCount++;
              }
            }
          }
        }
      }
    }
    for (const index in data){
      if(data[index]['surface'] === '0.00'){
        delete data[index]
      }
    }
    return data;
  };
  
  const mergedata = (dataExploit, dataDefaut)=> {
    var data = {};
    if(dataDefaut !== undefined) {
      for (const key in dataExploit) {
        if (dataExploit.hasOwnProperty(key)) {
          const exploitEntry = dataExploit[key][Object.keys(dataExploit[key])[0]];;
          const defautEntry = dataDefaut[key];
    
          data[key] = {
            libelle: exploitEntry.libelle,
            SelectedSpe: exploitEntry.SelectedSpe,
            surface: exploitEntry.surface,
            Semis:"",
            rendement: exploitEntry.rendement,
            rendementConv: defautEntry.rendement,
            rendementBio: defautEntry.rendementBio,
            rendementIrrig: defautEntry.rendementIrrig,
            prix: exploitEntry.prix,
            prixConv: defautEntry.prix,
            prixBio: defautEntry.prixBio,
            Specification: defautEntry.specifications,
          };
        }
      }
    }
    return data;
  } 
  const EnvoiResultats = (data, typeCulture, dataPlusCult)=> { //permet de confirgurer les valeurs pour quelles ressortent de manière simplifiées
    var dataOutput = {};
    if(data !== undefined && dataPlusCult !== undefined) { //on vérifie que les données ont bien été récupérées par l'API
      for (const key in data) { //on fait le tour des données de l'exploitation
        if (data.hasOwnProperty(key)) {
          const dataEntry = data[key];
          const speInThisCultre = []
          for (const keyDPC in dataPlusCult) { //on fait le tour des données des culrures duppliquée
            if (dataPlusCult.hasOwnProperty(keyDPC)) {
              const selectedSpeDataPlusCult = dataPlusCult[keyDPC]['key'];
              const selectedSpeData = key;
  
              if ( // on fait une liste s'il existe d'autre spé pour chaque culture 
                selectedSpeDataPlusCult &&
                selectedSpeData &&
                selectedSpeDataPlusCult.toLowerCase() === selectedSpeData.toLowerCase()
              ) {
                speInThisCultre.push(keyDPC);
              }
            }
          }
          dataOutput[key] = {};
          dataOutput[key][dataEntry['SelectedSpe'].toString()] = {
            libelle: dataEntry.libelle,
            SelectedSpe: dataEntry.SelectedSpe,
            surface: dataEntry.surface,
            rendement: dataEntry.rendement,
            prix: dataEntry.prix,
            typeCulture: typeCulture,
            Semis:dataEntry.Semis,
          };
          if (speInThisCultre.length !== 0) {
            for (const indice in speInThisCultre) {
              const index = speInThisCultre[indice]
              const speSelectionnee = dataPlusCult[index]['SelectedSpe'].toString()
              dataOutput[key][speSelectionnee] = {
                libelle: dataPlusCult[index]['libelle'],
                SelectedSpe: dataPlusCult[index]['SelectedSpe'],
                surface: dataPlusCult[index]['surface'],
                rendement: dataPlusCult[index]['rendement'],
                prix: dataPlusCult[index]['prix'],
                typeCulture: typeCulture,
                Semis:dataPlusCult[index]['Semis'],
              };
            }
          }          
        }
      }
    }
    return dataOutput;
  } 
  //Fonction qui prend en entrée la même chose que la précédente et utilise la précédente pour initialiser les données puis prend aussi dataDefaut pour compléter les spé que l'on a pas 

  const EnvoiCompletResultats = (data, typeCulture, dataPlusCult, dataDefault) => {
    const dataOutPut = EnvoiResultats(data, typeCulture, dataPlusCult)
    if (dataOutPut !== undefined && dataDefault !== undefined) {
      // On vérifie que les données ont bien été récupérées par l'API
      for (const key in dataOutPut) {
        if (dataOutPut.hasOwnProperty(key)) {
          const specifications = dataDefault[key]['specifications'];
  
          if (!dataOutPut[key].hasOwnProperty('none')) {
            dataOutPut[key]['none'] = {
              libelle: dataDefault[key]['libelle'],
              SelectedSpe: 'none',
              surface: '0.00',
              rendement: '0.00',
              prix: '0.00',
              typeCulture: 'Conventionnelle',
              Semis:""
            };
          }
  
          for (const specKey in specifications) {
            if (specifications.hasOwnProperty(specKey)) {
              if (!dataOutPut[key].hasOwnProperty(specKey)) {
                dataOutPut[key][specKey] = {
                  libelle: dataDefault[key]['libelle'],
                  SelectedSpe: specKey,
                  surface: '0.00',
                  rendement: specifications[specKey]['rendement'],
                  prix: specifications[specKey]['prix'],
                  typeCulture: 'Conventionnelle',
                  Semis:""
                };
              }
            }
          }
        }
      }
    }
    return dataOutPut;
  } 

  function EnvoiCompletResultatsCepage(Cepage, dataPlusCultCepage) {
    var dataOutPut = {}
    var newLigne = {
      [1]:{
        "key": Cepage["Cepage"]["SelectedSpe"],
        "surface":Cepage["Cepage"]["surface"],
        "rendement":Cepage["Cepage"]["rendement"],
        "prix":Cepage["Cepage"]["prix"],
      }  
    }
    dataOutPut = { ...dataOutPut, ...newLigne };
    if (Object.keys(dataPlusCultCepage).length > 0){
      var clef = 2
      for (let key in dataPlusCultCepage) {
        var newCepage = {
          [clef]:{
            "key": dataPlusCultCepage[key]["SelectedSpe"],
            "surface":dataPlusCultCepage[key]["surface"],
            "rendement":dataPlusCultCepage[key]["rendement"],
            "prix":dataPlusCultCepage[key]["prix"],
          }  
        }
        clef += 1
        dataOutPut = { ...dataOutPut, ...newCepage };
      }
    }
    return dataOutPut
  }

  //Liste avec code 3 et libelle des cultures disponible à la duplication
  const culturesDispo = (ClickedGC, ClickedViti) => {
    var obj 
    if(ClickedGC){
      obj = Object.keys(data).map((item)=>( item !=="630" && item !=="670" && item !=="675"?
        {"key": item, "label":data[item].libelle, "nbspe":Object.keys(data[item]["Specification"]).length} : null
      ))
    }
    else if (ClickedViti){
      obj = Object.keys(data).map((item)=>( item ==="630" || item ==="670" || item ==="675"?
        {"key": item, "label":data[item].libelle, "nbspe":Object.keys(data[item]["Specification"]).length} : null
      ))
    }
    else {
      obj = Object.keys(data).map((item)=>( 
        {"key": item, "label":data[item].libelle, "nbspe":Object.keys(data[item]["Specification"]).length}
      ))
    }
    return obj
  }

  const [ShowDupliquerProd, setShowDupliquerProd]= useState(false);
  const [ShowAjouterProd, setShowAjouterProd]= useState(false);
  const [ClickedGC, setClickedGC]= useState(false);
  const [ClickedViti, setClickedViti]= useState(false);
  const [ClickedMixte, setClickedMixte]= useState(true);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem('idAgent'));
    const items2 = JSON.parse(localStorage.getItem('idExploitationPrinicpale'));
    fetch(apiLink + 'getExploitation/' + items2) // récupère assolement exploitation principale
    .then((response) => {
      if (response.ok) { // test si le compte a été réinitialisé
        return response.json();
      } else {
       localStorage.clear()
        setGoToHome(true)
      }
    })
    .then(response =>{
      var bio = false 
      var irrig = false 
      if ("none" in response["111"]) {
        bio = response["111"]["none"]["bio"]
        irrig = response["111"]["none"]["irrigation"]
      }
      else{
        bio = response["111"]["1"]["bio"]
        irrig = response["111"]["1"]["irrigation"]
      }
      var TC = ""
      if (bio === true){
        TC = "Biologique"
      }
      else if (irrig === true){
        TC = "Irriguee"
      }
      else {
        TC = "Conventionnelle"
      }
      setTypeCulture(TC)
      setTypeCulture(prev => {
          return prev;
      })
      var DExp = response
      fetch(apiLink + 'getLocalisation/' + items2)
        .then( (response) => {
            return response.json()
        })
        .then(response =>{
          let city
          city = response.idCommune
          setCity(response.idCommune)
          fetch(apiLink + 'getExploitationDefault/' + city)
          .then( (response) => {
            return response.json()
          })
          .then(response =>{
            var DDef=response
            setData(mergedata(DExp,DDef))
            setdataPlusCult(CreaDataDupliquee(DExp,DDef))
            setDataDefaut(response)
            setDataDefaut(prev => {
              return prev;
            })
        })
      })
      setDataExploit(response)
      setDataExploit(prev => {
      return prev;
      })
    })
      
    fetch(apiLink + 'getExploitations/' + items) //cherche toutes les exploit pour les compter
    .then( (response) => {
        return response.json()
    })
    .then(response =>{
      setName('Simulation ' + (Object.values(response).length + 1))
      setName(prev => {
          return prev;
      })
    })
    }, []);

  //permet de stocker si bio, conventionnel ou irrigué
   //permet de stocker si bio, conventionnel ou irrigué
   function handleChangeTypeCulture(event) {
    var TC = event.target.value;
    setTypeCulture(TC);
    Object.keys(data).map(key => {
      var newData = { ...data };
      if (TC === "Conventionnelle") {
        if (data[key]['SelectedSpe'] !== "none") {
          newData[key]["rendement"] = data[key]['Specification'][data[key]['SelectedSpe']]['rendement'];
          newData[key]["prix"] = data[key]['Specification'][data[key]['SelectedSpe']]['prix'];
        } else {
          newData[key]["rendement"] = data[key]['rendementConv'];
          newData[key]["prix"] = data[key]['prixConv'];
        }
      } else {
        if (data[key]['SelectedSpe'] !== "none") {
          newData[key]["rendement"] = data[key]['Specification'][data[key]['SelectedSpe']]['rendementBio'];
          newData[key]["prix"] = data[key]['Specification'][data[key]['SelectedSpe']]['prixBio'];
        } else {
          newData[key]["rendement"] = data[key]['rendementBio'];
          newData[key]["prix"] = data[key]['prixBio'];
        }
      }
      setData(newData);
    });
    Object.keys(dataPlusCult).map(keyDC => {
      var newDataPlusCult = { ...dataPlusCult };
      if (TC === "Conventionnelle") {
        if (dataPlusCult[keyDC]['SelectedSpe'] !== "none") {
          newDataPlusCult[keyDC]["rendement"] = dataPlusCult[keyDC]['Specification'][dataPlusCult[keyDC]['SelectedSpe']]['rendement'];
          newDataPlusCult[keyDC]["prix"] = dataPlusCult[keyDC]['Specification'][dataPlusCult[keyDC]['SelectedSpe']]['prix'];
        } else {
          newDataPlusCult[keyDC]["rendement"] = dataPlusCult[keyDC]['rendementConv'];
          newDataPlusCult[keyDC]["prix"] = dataPlusCult[keyDC]['prixConv'];
        }
      } else {
        if (dataPlusCult[keyDC]['SelectedSpe'] !== "none") {
          newDataPlusCult[keyDC]["rendement"] = dataPlusCult[keyDC]['Specification'][dataPlusCult[keyDC]['SelectedSpe']]['rendementBio'];
          newDataPlusCult[keyDC]["prix"] = dataPlusCult[keyDC]['Specification'][dataPlusCult[keyDC]['SelectedSpe']]['prixBio'];
        } else {
          newDataPlusCult[keyDC]["rendement"] = dataPlusCult[keyDC]['rendementBio'];
          newDataPlusCult[keyDC]["prix"] = dataPlusCult[keyDC]['prixBio'];
        }
      }
      setdataPlusCult(newDataPlusCult);
    });
    
  }

  // changer le nom de la simulation
  function handleChangeName(event) {
    var Name = event.target.value
    setName(Name) 
  }

  //tableau des cultures de base
  const table_assol=  Object.keys(data).map(key => (
    <Culture 
    keys={key}
    data={data}
    MAJ_surf_rdt_prix_spe={MAJ_surf_rdt_prix_spe}
    ClickedGC={ClickedGC}
    ClickedMixte= {ClickedMixte}
    ClickedViti={ClickedViti}
    libelle={data[key]["libelle"]}
    showSemis={showSemis}
    typeCulture={TypeCulture}
    />
  ))
  //tableau des cultures dupliqué pour ajouter des spés
  const table_assol_spe_sup = Object.keys(dataPlusCult).map(clef => (
    <CultureAjoutSpe 
    keys={clef}
    data={dataPlusCult}
    MAJ_surf_rdt_prix_spe={MAJ_surf_rdt_prix_spe_Cult_Plus}
    SuppressionSpe={SuppressionSpe}
    libelle={dataPlusCult[clef]["libelle"]}
    showSemis={showSemis}
    />
  ))
  const table_assol_cepage_plus = Object.keys(dataPlusCultCepage).map(clef => (
    <CultureAjoutCepage
    keys={clef}
    data={dataPlusCultCepage}
    MAJ_surf_rdt_prix_spe={MAJ_surf_rdt_prix_spe_Cult_Plus_Cepage}
    SuppressionSpe={SuppressionSpeCepage}
    libelle={dataPlusCultCepage[clef]["libelle"]}
    showSemis={showSemis}
    typeCulture={TypeCulture}
    /> 
  ))

  //choix de la spé à dupliquer
  function handlechangeOtherSpe(event) {
    setValueSpeAdddOtherSpe(event.target.value)
  }

  //Ajout d'une nouvelle culture dans les liste de celles dupliquées
  function addOtherSpe(){
    var lengthSpe = Object.keys(data[valueSpeaddOtherSpe]["Specification"]).length
    var Nbligne = 0
    for (var item in dataPlusCult) {
      if (dataPlusCult[item]['key'] === valueSpeaddOtherSpe) {
        Nbligne += 1;
      }
    }
    if(Nbligne < lengthSpe) {
      var sumDPC = Object.keys(dataPlusCult).pop() //récupère la dernière clef de l'objet
      var clef = 0
      if(sumDPC === undefined){
        clef = 1
      }
      else {
        clef = parseInt(sumDPC) + 1
      }
      var newLigne = {
        [clef]:{
          "key": valueSpeaddOtherSpe,
          "libelle":data[valueSpeaddOtherSpe]["libelle"],
          "SelectedSpe":"none",
          "surface":0.00,
          "rendement":data[valueSpeaddOtherSpe]["rendement"],
          "rendementConv" :data[valueSpeaddOtherSpe]["rendementConv"],
          "rendementBio":data[valueSpeaddOtherSpe]["rendementBio"] ,
          "prix":data[valueSpeaddOtherSpe]["prix"],
          "prixConv":data[valueSpeaddOtherSpe]["prixConv"],
          "prixBio":data[valueSpeaddOtherSpe]["prixBio"],
          "Specification": data[valueSpeaddOtherSpe]["Specification"],
          "Semis":""
        }  
      }
      setdataPlusCult({...dataPlusCult, ...newLigne});
      //setdataPlusCult(Object.assign(dataPlusCult,newLigne))
    }
    else {
      alert("Vous ne pouvez pas ajouter plus de ligne de culture qu'il n'y a de spécification")
    }
  }
  function addOtherCepage(lengthSpe){
    var Nbligne = 0
    for (const key of Object.keys(dataPlusCultCepage)) {
      Nbligne += 1;
  }
    if(Nbligne < lengthSpe) {
      var sumDPC = Object.keys(dataPlusCultCepage).pop() //récupère la dernière clef de l'objet
      var clef = 0
      if(sumDPC === undefined){
        clef = 1
      }
      else {
        clef = parseInt(sumDPC) + 1
      }
      var newLigne = {
        [clef]:{
          "key": "Cepage",
          "libelle":Cepage["Cepage"]["libelle"],
          "SelectedSpe":"none",
          "surface":0.00,
          "rendement":Cepage["Cepage"]["rendement"],
          "prix":Cepage["Cepage"]["prix"],
          "Specification": Cepage["Cepage"]["Specification"],
        }  
      }
      setdataPlusCultCepage({...dataPlusCultCepage, ...newLigne});
      //setdataPlusCult(Object.assign(dataPlusCult,newLigne))
    }
    else {
      alert("Vous ne pouvez pas ajouter plus de ligne de cépage car vous avez atteint le nombre maximum de clef")
    }
  }

  //fonction de suppression d'une culture dupliquée
  function SuppressionSpe(key){
    const updateddata = { ...dataPlusCult};
    delete updateddata[key];
    setdataPlusCult(updateddata);
    setdataPlusCult(prev => {
      return prev
    })
  }
  function SuppressionSpeCepage(key){
    const updateddata = { ...dataPlusCultCepage};
    delete updateddata[key];
    setdataPlusCultCepage(updateddata);
    setdataPlusCultCepage(prev => {
      return prev
    })
  }

  // Mise à jour des données de surfaces, rdt, prix et spécification de chaque culture
  function MAJ_surf_rdt_prix_spe(key,type, valeur) {
    var newData = {...data};
    if (typeof valeur === "string") {
      newData[key][type] = valeur
    }
    else { 
      newData[key][type] = parseFloat(valeur)
    }
    setData(newData)
    setData(prev => {
      return prev;
    })
  }
  function MAJ_surf_rdt_prix_spe_Cepage(key,type, valeur) {
    var newData = {...Cepage};
    if (typeof valeur === "string") {
      newData[key][type] = valeur
    }
    else { 
      newData[key][type] = parseFloat(valeur)
    }
    setDataCepage(newData)
    setDataCepage(prev => {
      return prev;
    })
  }
  function MAJ_surf_rdt_prix_spe_Cult_Plus(key,type, valeur) {
    var newData = {...dataPlusCult};
    if (typeof valeur === "string") {
      newData[key][type] = valeur
    }
    else { 
      newData[key][type] = parseFloat(valeur)
    }
    setdataPlusCult(newData)
    setdataPlusCult(prev => {
      return prev;
    }) 
  }
  function MAJ_surf_rdt_prix_spe_Cult_Plus_Cepage(key,type, valeur) {
    var newData = {...dataPlusCultCepage};
    if (typeof valeur === "string") {
      newData[key][type] = valeur
    }
    else { 
      newData[key][type] = parseFloat(valeur)
    }
    setdataPlusCultCepage(newData)
    setdataPlusCultCepage(prev => {
      return prev;
    }) 
  }

  // calcul du total des surfaces
  const total_surf1 = (data) => {
    var surf_tot1 = 0
    for (var key in data ) {
      surf_tot1 +=  parseFloat(data[key]["surface"])
    }
    return surf_tot1
  }
  const total_surf2 = (dataPlusCult) => {
    var surf_tot2 = 0
    if (dataPlusCult === undefined) {
      surf_tot2 = 0
    }
    else {
      for (var key in dataPlusCult ) {
        surf_tot2 +=  parseFloat(dataPlusCult[key]["surface"])
      }
    }
    return surf_tot2
  }
  const total_surf = (data, dataPlusCult) => { 
    return total_surf1(data) + total_surf2(dataPlusCult) 
  }

  // calcul du total des PB
  const total_PB1 = (data) => {
    var PB_tot1 = 0
    for (var key in data ) {
      if(parseFloat(data[key]["surface"]) !== 0){
        PB_tot1 += Math.round( parseFloat(data[key]["surface"])* parseFloat(data[key]["rendement"])* parseFloat(data[key]["prix"]))
      } else {
        PB_tot1 +=0
      }

      
    }
    return PB_tot1
  }
  const total_PB2 = (dataPlusCult) => {
    var PB_tot2 = 0
    if (dataPlusCult === undefined) {
      PB_tot2 = 0
    }
    else {
      for (var key in dataPlusCult ) {
        if (parseFloat(dataPlusCult[key]["surface"]) !== 0){
          PB_tot2 += Math.round( parseFloat(dataPlusCult[key]["surface"])* parseFloat(dataPlusCult[key]["rendement"])* parseFloat(dataPlusCult[key]["prix"]))
        } else {
          PB_tot2 += 0
        }
        
      }
    }
    return PB_tot2
   
  }
  const total_PB = (data, dataPlusCult) => {
    return (total_PB1(data) + total_PB2(dataPlusCult))
  }

  function formatDateSemis(data, dataPlusCult) {
    var formatIsValid = true; // Initialise à true
    var regex = /^[0-3]{1}[0-9]{1}\.[0-1]{1}[0-9]{1}$/;
  
    for (var key in data) {
      if (parseFloat(data[key]["surface"]) !==0 && !regex.test(data[key]["Semis"])) {
        formatIsValid = false;
        break; // Si une correspondance invalide est trouvée, on peut sortir de la boucle
      }
    }
    if (formatIsValid) {
      for (var key in dataPlusCult) {
      if (parseFloat(dataPlusCult[key]["surface"]) !== 0 && !regex.test(dataPlusCult[key]["Semis"])) {
        formatIsValid = false;
        break; // Si une correspondance invalide est trouvée, on peut sortir de la boucle
      }
    }
    }
  
    return formatIsValid;
  }
  

  // cherche si une culture dupliqué n'a pas de surface 
  function Spe_dupliquee_sans_surf(dataPlusCult){
    var surfNull= false 
    for (var key in dataPlusCult ) {
      if (dataPlusCult[key]["surface"] === 0) {
        surfNull = true
      }
    }
    return surfNull
  }

  // cherche si une production à deux fois la même spé 
  function prod_spe_en_double(data, dataPlusCult){
    var PED = false 
    for (var keyD in data ) { // on fait le tour des productions de base 
      var SpeList = data[keyD]["Specification"] // on récupère la liste des spécifications
      var SpeSelected = {"none": 0} //initialisation de liste avec le sans spé
      for (var keySL in SpeList){
        Object.assign(SpeSelected,{[keySL]:0}) 
      }
      var firstSpe = data[keyD]["SelectedSpe"]
      SpeSelected[firstSpe] += 1// on ajoute 1 pour la spé sélectionné par la première culture 
      for (var keyDC in dataPlusCult ) { // a chaque fois on fait le tour des productions duppliquées
        if(dataPlusCult[keyDC]["key"] === keyD) {
          var SS = dataPlusCult[keyDC]["SelectedSpe"]
          SpeSelected[SS] += 1  //si la key est égale à keyD alors on ajoute 1 à la spé
        }
      }
      for (var keySS in SpeSelected){
        if(SpeSelected[keySS] > 1){
          PED = true //si une spé à plus de 1 itération on arrête la fonction et on return true
          return PED
        }
      }
    }
    return PED
  }

  function surface_viti_non_egale(data, dataPlusCult, Cepage, dataPlusCultCepage) { //permet de déterminer si les surface en vin sont égale aux surfaces en cépage
    var surfaceVin = 0
    var surfaceCepage = parseFloat(Cepage["Cepage"]["surface"]) // la première surface des cépages
    // ajout des cépages en plus
    if (Object.keys(dataPlusCultCepage).length > 0){ // s'il y a des cépages en plus du premier
      for (let keyC in dataPlusCultCepage) { // on boucle sur les clefs
        surfaceCepage += parseFloat(dataPlusCultCepage[keyC]["surface"]) // on ajoute toutes les surfaces de chaque cépage en plus 
      }
    }
    //partie vin : on cherche les surfaces des vins s'ils existent
    //dans un premier temps dans les données principales
    for (let keyD in data) { // on boucle sur les clefs des datas (donc des cultures avec un code3 donc des vins)
      if (keyD == '630' || keyD === '670' || keyD === '675'){
        surfaceVin += parseFloat(data[keyD]["surface"]) // on ajoute toutes les surfaces de chaque vin
      }
    }
    //puis dans les données duppliquées
    if (Object.keys(dataPlusCult).length > 0){ // s'il y a des vins en plus du premier
      for (let keyCu in dataPlusCult) { // on boucle sur les clefs
        if (dataPlusCult[keyCu]['key'] === '630' || dataPlusCult[keyCu]['key'] === '670' || dataPlusCult[keyCu]['key'] === '675')
        surfaceVin += parseFloat(dataPlusCult[keyCu]["surface"]) // on ajoute toutes les surfaces de chaque vin en plus 
      }
    }

    if (surfaceCepage === surfaceVin || (surfaceVin !== 0 && surfaceCepage === 0)) {
      return false // si les résulats sont égaux alors ils ne sont pas "non égale" alors false
    }
    else {
      return true
    }
  }

  var prod = JSON.stringify(EnvoiCompletResultats(data, TypeCulture, dataPlusCult, dataDefaut))
  mesDonnees.set("productions", prod);
  mesDonnees.set("nom", Name);

  // foinction d'envoi des données au serveur
  function handleSubmit(event) {
    var surfaceNull = Spe_dupliquee_sans_surf(dataPlusCult)
    var productionEnDouble = prod_spe_en_double(data, dataPlusCult)
    var surfaceVitiNonEgale = surface_viti_non_egale(data, dataPlusCult, Cepage, dataPlusCultCepage) 
    event.preventDefault()
    if (data["630"] !== undefined && ((parseFloat(data["630"]["surface"])===0 && data["630"]["SelectedSpe"]!=="none") || (parseFloat(data["670"]["surface"])===0 && data["670"]["SelectedSpe"]!=="none") || (parseFloat(data["675"]["surface"])===0 && data["675"]["SelectedSpe"]!=="none"))){
      alert('Vous devez saisir une surface pour la spécification de vin choisit')
    }
    else if (surfaceNull === true) {
      alert("L'une des productions duppliquées ne possède pas de surface, veuillez soit : \n- Supprimer cette production \n- Ajouter une surface à cette production ")
    }
    else if (productionEnDouble === true){
      alert("L'une de vos production possède deux (ou plus) fois la même spécification, veuillez soit \n - Supprimer le production avec la spécification en double \n - Changer la spécification ")
    }
    else if (surfaceVitiNonEgale === true){
      alert("Le total de vos surfaces en terme de cépage doivent être égale au total de vos surfaces en vin")
    }
    else{ 
      var prod = JSON.stringify(EnvoiCompletResultats(data, TypeCulture, dataPlusCult, dataDefaut))
      mesDonnees.set("productions", prod);
      mesDonnees.set("nom", Name);

      axios({
          method: 'post',
          url: apiLink + 'setExploitation/' + idAgent,
          data: mesDonnees
      })
      .then(function (reponse) {
        if(reponse.status === 200){
          var idExpC = reponse.data.idExploitation
          var cepageList = JSON.stringify(EnvoiCompletResultatsCepage(Cepage, dataPlusCultCepage))
          mesDonneesP.set("productions", prod);
          mesDonneesP.set("nom", Name);
          mesDonneesP.set("idCommune", city);
          mesDonneesP.set("IdExploitation", idExpC);
          var JSON_P = {"productions" : prod, "nom": Name, "idCommune": city, "idExploitation":idExpC, "cepage": cepageList}
         
          setidExploit(idExpC)
          
          const jsonData = JSON.stringify(JSON_P);

          axios.post('https://api2050.diagorisk.com/newSimul/', jsonData)
            .catch(error => {
              // Handle errors here
              console.error('Error:', error);
            });

          setGoToExploit(true)
        }
        else {
          alert("Une erreur c'est produite, votre assolement n'a pas pû être enregistré")
        }
      })
      .catch(function (erreur) {
          //On traite ici les erreurs éventuellement survenues
          console.log(erreur);
      }); 
    } 
  }
  function handleShowSemis(){
    if (showSemis){
      setShowSemis(false)
    }
    else {
      setShowSemis(true)
    }
  }

  function handleShowDupliProd() {
    setShowDupliquerProd(true)
  }
  function handleHideDupliProd() {
      setShowDupliquerProd(false)
  }
  function handleShowAjouProd() {
      setShowAjouterProd(true)
  }
  function handleHideAjouProd() {
      setShowAjouterProd(false)
  }
  function handleClickedGC() {
    setClickedGC(true)
    setClickedViti(false)
    setClickedMixte(false)
  }
  function handleClickedViti() {
    setClickedGC(false)
    setClickedViti(true)
    setClickedMixte(false)
  }
  function handleClickedMixte() {
    setClickedGC(false)
    setClickedViti(false)
    setClickedMixte(true)
  }

  if(goToExploit) {
    return <Navigate push to={`/exploitation`} state={{idExploit}}/>
  }
  if(goToHome) {
    return <Navigate push to={`/`} />
  }


  // contenu de la page 
  const new_simulation = (
    <div >
      <div className='paragraphe'> Cette nouvelle simulation se base sur l'assolement que vous avez fournit lors de la création de votre compte. Veuillez modifier les champs souhaités.</div>
      <div className='titre'> Nom de la simulation : 
        <input 
        className='inputNomSimul'
        value={Name}
        onChange={handleChangeName}
        placeholder='Nom'
        type='text'
        required/>
      </div>
      <div className='dispLigne'>
        <button className= {ClickedGC ? 'validation validationCompGCVITIMI valCompGCC' : 'validation validationCompGCVITIMI valCompGCUnC'} onClick={handleClickedGC} >
          Grandes cultures
        </button>
        <button className= {ClickedViti ?'validation validationCompGCVITIMI valCompVitiC' : 'validation validationCompGCVITIMI valCompVitiUnC'} onClick={handleClickedViti}>
          Viticulture
        </button>
        <button className= {ClickedMixte ?'validation validationCompGCVITIMI valCompMixteC' : 'validation validationCompGCVITIMI valCompMixteUnC'} onClick={handleClickedMixte}>
          Mixte
        </button>
      </div>
      
      {TypeCulture !== "" ? (
        <div className='row' onChange={handleChangeTypeCulture} style={{justifyContent:'left', marginBottom:'0px'}}> 
          <input type="radio" value="Conventionnelle" name="CultureType" className='input_radio' defaultChecked={TypeCulture === "Conventionnelle" }/>  <label className='paragraphe'> Conventionnelle </label>
          <input type="radio" value="Biologique" name="CultureType" className='input_radio' defaultChecked={TypeCulture === "Biologique"}/> <label className='paragraphe'> Biologique </label>
          {/* <input type="radio" value="Irriguee" name="CultureType" className='input_radio'defaultChecked={TypeCulture === "Irriguee"}/> <label className='paragraphe'> Irriguée </label> */}
        </div> 
      ):""}
      <div className='table_assol'>
        <div className='row ligne_assol'>
          <div className='col_assol col_libelle_entete'>
            <b>Libellé </b>
          </div>
          <div className='col_assol col_spe_entete'>
            <b>Spécification </b>
          </div>
          {/* <button className='col_buttonSemis' onClick={handleShowSemis}>{showSemis === true ? "-" : "+"}</button>
          <div className={showSemis ? 'col_assol col_semis showButtonSemisTrue ' : 'col_assol col_semis showButtonSemisFalse '}>
            <b>Date de semis </b>
          </div> */}
          <div className='col_assol col_surface'>
            <b>Surface </b>
          </div>
          <div className='col_assol col_rdt'>
            <b>Rendement </b>
          </div>
          <div className='col_assol col_prix'>
            <b>Prix </b>
          </div>
          <div className='col_assol col_PB_unitaire'>
            <b style={{textAlign:'center'}}>Produit brut par ha </b>
          </div>
          <div className='col_assol col_PB_tit_Prod'>
            <b style={{textAlign:'center'}}> Produit brut total </b>
          </div>
        </div>
        {Object.keys(data).length === 0 ?"":table_assol}
        <Culture 
          keys={"Cepage"}
          data={Cepage}
          MAJ_surf_rdt_prix_spe={MAJ_surf_rdt_prix_spe_Cepage}
          ClickedGC={ClickedGC}
          ClickedMixte= {ClickedMixte}
          ClickedViti={ClickedViti}
          libelle={Cepage["Cepage"]["libelle"]}
          showSemis={showSemis}
          typeCulture={TypeCulture}
          />
        {Object.keys(dataPlusCultCepage).length === 0 ?"": table_assol_cepage_plus }
        {Object.keys(dataPlusCult).length === 0 ?"": table_assol_spe_sup}
        
        <div className='row ligne_assol'>
          <div className='col_assol col_TotFinal' style={{fontWeight:'bold'}}>
            Total 
          </div>
          <div className='col_assol col_surface' style={{fontWeight:'bold'}}>
            {Math.round(total_surf(data, dataPlusCult)*100)/100} ha
          </div>
          <div className='col_assol col_PB_tot' style={{fontWeight:'bold'}}>
            {total_PB(data, dataPlusCult).toLocaleString()} €
          </div>
        </div>
      </div>

      <div className='masquer_en_portrait' style={{marginTop:'20px', width:'100%'}}>
        <div className='dispLigne' style={{alignItems:'center', width:'100%'}}>
          <button 
            className={ShowDupliquerProd ? 'validation validationCompAssol valCompOrange' : 'validation validationCompAssol valCompVert'} 
            onClick={ShowDupliquerProd ? handleHideDupliProd : handleShowDupliProd}>
            Ajouter une autre spécification à une culture existante
          </button>

          <div className={ShowDupliquerProd ? 'ShowResult ' : 'HideResult'} style={{width:"65%"}}>
            <select  style={{height:'50px', margin:'0px', width:'35%', marginLeft:"40px", marginRight:"20px"}} onChange={handlechangeOtherSpe}>
              <option value="">Sélectionnez la production à dupliquer</option>
              {culturesDispo(ClickedGC, ClickedViti).map((item)=>( item && <option key={item.key} value={item.key} > {item && item.label} </option> ) ) }
            </select>
            <button className='validation' onClick={addOtherSpe} style={{backgroundColor:'#d5d5d5', marginTop:'0px', height:'80%', margin:'0px', width:'25%'}}> Dupliquer la production </button>
          </div>
        </div>


      </div>
      <button 
        className='validation validationCompAssol valCompVert'
        onClick={() => addOtherCepage(18)}>
        Ajouter un cépage
      </button>
      
      <div className='row'>
        <button onClick={handleSubmit} className='validation'> Enregistrer </button> 
      </div>
    </div>
  )

  return (
    <div> 
      <Entete titre="Nouvelle simulation"/>  
      <div className='container'> 
        {idExploitationPrincipale!==null ? new_simulation : <LocalisationIns />}
      </div>
      <ButtonQuestions/>
    </div>
  );
}

export default NewSimulation
import React, {useState, useEffect} from 'react'
import TitreRapport from './titre_rapport.js'
import img from '../../assets/page_1_rapport.jpg'
import apiLink from '../../config.js'

function PageHow({idExploit}) {
  const [ExploitMixte, setExploitMixte] = useState(false)
  const [ExploitViti, setExploitViti] = useState(false)
  const [ExploitGC,setExploitGC] = useState(true)

  function fetchData() {
    fetch(apiLink + 'page/production-rapport/' + idExploit)
    .then( (response) => {
    return response.json()
    })
    .then(response =>{
    let data = response
    for (var cle in data) {
        if (cle.includes("Vignes")) {
            setExploitMixte(true)
            setExploitGC(false)
            setExploitViti(false);
            break;
        }
    }
    let allKeysContainVignes = Object.keys(data).every(cle => cle.includes("Vignes"));
    if (allKeysContainVignes) {
        setExploitViti(true);
        setExploitMixte(false)
        setExploitGC(false)
    } else {
        setExploitViti(false);
    }
    })
}

  useEffect ( () => {
      fetchData()
    }, [idExploit])

  return (
    <div className='containerPrint'>
      <TitreRapport Titre={'Comment a été élaboré ce rapport ?'} Page={ExploitGC ? '10': ExploitMixte ? "11" : "10"}/>
      <div className='row AG_rapport'> 
        <div style={{fontWeight:'bold',fontSize:'25px', marginRight:'50px', textAlign:'center', marginTop:'60px', width:'30%'}}> Localisation + assolement </div>
        <img src={img} className='image_how_rapport' alt="logo_diagorisk"/>
        <div style={{fontWeight:'bold',fontSize:'25px', marginLeft:'50px', marginRight:'20px', marginTop:'60px', width:'30%'}}> Ce rapport </div>
      </div>

      <div className='howParagraphe'>
        <div style={{color:'#92D050', fontSize:'30px',fontWeight:'bold'}}> Fonctionnement de l’outil d’évaluation des risques</div>
        <ul>
          <li> <div className='lila pointHow'>  Les risques Rendement et Prix sont analysées par production à l’échelle des 432 (Petites) Régions Agricoles, à partir des </div> </li>
          <ul>
            <li> <div className='lila pointHow'>  Données comptables du RICA de 7 600 exploitations sur 20 ans </div> </li>
            <li> <div className='lila pointHow'>  Statistiques de Rendement et de prix de 1989 à ce jour. </div> </li>
            <li> <div className='lila pointHow'>  Les projetction à 2050 sont réalisées à partir des données misent à disposition pour Météo France sur le portail DRIAS. </div> </li>
          </ul>
          <li> <div className='lila pointHow'>  Cette modélisation est comparable à celle que font les assureurs pour établir leur tarif. </div> </li>
          <li> <div className='lila pointHow'>  L’outil compare votre situation aux résultats d’exploitations types de votre région. </div> </li>
          <li> <div className='lila pointHow'>  Il réalise des simulations des indemnisations d’assurance MRC et de leurs options combinées ou non avec l’épargne de précaution disponible ou d’autres éléments. </div> </li>
          <li> <div className='lila pointHow'>  Vous pouvez comparer les résultats et le rapport Sécurité – Coût des différentes stratégies. </div> </li>
        </ul>
      </div>
      
    </div>
  )
}

export default PageHow
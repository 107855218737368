import React, {useEffect} from 'react'
import TitreRapport from './titre_rapport.js'
import apiLink from '../../config.js'
import podium1 from '../../assets/podium1.png'
import podium2 from '../../assets/podium2.png'
import podium3 from '../../assets/podium3.png'
import AFOM_A from '../../assets/AFOM-A.png'
import AFOM_F from '../../assets/AFOM-F.png'
import AFOM_O from '../../assets/AFOM-O.png'
import AFOM_M from '../../assets/AFOM-M.png'

import BleT from '../../assets/blé-tendre.png'
import BleD from '../../assets/blé-dur.png'
import Orge from '../../assets/orge.png'
import Mais from '../../assets/maïs.png'
import Colza from '../../assets/colza.png'
import Viti from '../../assets/viti.png'
import Betterave from '../../assets/betterave.png'
import Chanvre from '../../assets/chanvre.png'
import Feverole from '../../assets/feverol.png'
import Luzerne from '../../assets/luzerne.png'
import PdT from '../../assets/pdt.png'
import Pois from '../../assets/pois_prot.png'
import Soja from '../../assets/soja.png'
import Tournesol from '../../assets/tournesol.png'
import CultureNone from '../../assets/cultureNONE.png'

import loading_Data from '../../assets/loading-Data.gif'
import SH_ac from '../../assets/navig2050_SH_ac.png'
import EE_ac from '../../assets/navig2050_exceseau_ac.png'
import GEL_ac from '../../assets/navig2050_gel_ac.png'
import CDC_ac from '../../assets/navig2050_cdcl_ac.png'

function PageAFOM({idExploit}) {
  const [ExploitMixte, setExploitMixte] = React.useState(false)
  const [ExploitViti, setExploitViti] = React.useState(false)
  const [ExploitGC,setExploitGC] = React.useState(true)
  const [dataPodiumCA_2035_2D, setDataPodiumCA_2035_2D]= React.useState()
  const [dataPodiumCA_2035_4D, setDataPodiumCA_2035_4D]= React.useState()
  const [dataPodiumCA_2050_2D, setDataPodiumCA_2050_2D]= React.useState()
  const [dataPodiumCA_2050_4D, setDataPodiumCA_2050_4D]= React.useState()

  function fetchData() {
    fetch(apiLink + 'page/production-rapport/' + idExploit)
    .then( (response) => {
    return response.json()
    })
    .then(response =>{
    let data = response
    for (var cle in data) {
        if (cle.includes("Vignes")) {
            setExploitMixte(true)
            setExploitGC(false)
            setExploitViti(false);
            break;
        }
    }
    let allKeysContainVignes = Object.keys(data).every(cle => cle.includes("Vignes"));
    if (allKeysContainVignes) {
        setExploitViti(true);
        setExploitMixte(false)
        setExploitGC(false)
    } else {
        setExploitViti(false);
    }
    fetch('https://api2050.diagorisk.com/podiumAleas/?idExploitation=' + idExploit + '&Annee=2035&Rcp=4.5&Risque=Exc') 
    .then((response) => response.json())
    .then((response) => {
      setDataPodiumCA_2035_2D(response.dataPodiumCA)
      setDataPodiumCA_2035_2D(prev => {
        return prev;
      })
    })
    fetch('https://api2050.diagorisk.com/podiumAleas/?idExploitation=' + idExploit + '&Annee=2035&Rcp=8.5&Risque=Exc') 
    .then((response) => response.json())
    .then((response) => {
      setDataPodiumCA_2035_4D(response.dataPodiumCA)
      setDataPodiumCA_2035_4D(prev => {
        return prev;
      })
    })
    fetch('https://api2050.diagorisk.com/podiumAleas/?idExploitation=' + idExploit + '&Annee=2050&Rcp=4.5&Risque=Exc') 
    .then((response) => response.json())
    .then((response) => {
      setDataPodiumCA_2050_2D(response.dataPodiumCA)
      setDataPodiumCA_2050_2D(prev => {
        return prev;
      })
    })
    fetch('https://api2050.diagorisk.com/podiumAleas/?idExploitation=' + idExploit + '&Annee=2050&Rcp=8.5&Risque=Exc') 
    .then((response) => response.json())
    .then((response) => {
      setDataPodiumCA_2050_4D(response.dataPodiumCA)
      setDataPodiumCA_2050_4D(prev => {
        return prev;
      })
    })
  }, [idExploit])
    
}

  useEffect ( () => {
      fetchData()
    }, [idExploit])

  return (
    <div className='containerPrint'>
      <TitreRapport Titre={"Principaux problèmes (combinaison aléa/culture)"} Page={ExploitGC ? '8': ExploitMixte ? "9" : "8"}/>
      <div className='paragraphe'>Les tableaux ci-dessous présentent les principaux problèmes auxquels vous aurez à faire, soit une combinaison Aléa - Culture. Le pourcentage représente la perte maximale que vous pouvez craindre. </div>
      {dataPodiumCA_2035_2D !== undefined ?
      <div className='tableau_podium_rapport'> 
        <div className='row_tableau_prix_rapport'>
          <div className='data_tableau_podium_rapport  color-tab-podium-35-2D' style={{width:'99.3%',height:'30px' ,fontWeight:'bold'}}>2035 avec un réchauffement à +2°C </div>
        </div>
        <div className='row_tableau_prix_rapport'>
          <div className='data_tableau_podium_rapport_entete'> <img src={podium1} className='imageClassementPodium' alt="première place" /> </div>
          <div className='data_tableau_podium_rapport_entete'> <img src={podium2} className='imageClassementPodium' alt="deuxième place" /> </div>
          <div className='data_tableau_podium_rapport_entete'> <img src={podium3} className='imageClassementPodium' alt="troisième place" /> </div>
        </div>
        <div className='row_tableau_prix_rapport'>
          <div className='data_tableau_podium_rapport_entete'> 
            <div className='row'>
                <img src={dataPodiumCA_2035_2D["1"][0]["alea"] === 'SH' ? SH_ac :dataPodiumCA_2035_2D["1"][0]["alea"] === 'EE' ? EE_ac :dataPodiumCA_2035_2D["1"][0]["alea"] === 'CDC' ? CDC_ac :dataPodiumCA_2035_2D["1"][0]["alea"] === 'GEL' ? GEL_ac :''} alt='icone'  className="imageAleaPodiumRapport" />
                <img src={dataPodiumCA_2035_2D["1"][0]["culture"] === 1112 ||  dataPodiumCA_2035_2D["1"][0]["culture"] === 1113 ? BleT : dataPodiumCA_2035_2D["1"][0]["culture"] === 1122 ||  dataPodiumCA_2035_2D["1"][0]["culture"] === 1123 ? BleD : dataPodiumCA_2035_2D["1"][0]["culture"] === 114 ||  dataPodiumCA_2035_2D["1"][0]["culture"] === 115 ? Orge : dataPodiumCA_2035_2D["1"][0]["culture"] === 118 ? Mais : dataPodiumCA_2035_2D["1"][0]["culture"] === 222 ? Colza : (dataPodiumCA_2035_2D["1"][0]["culture"] === 630 || dataPodiumCA_2035_2D["1"][0]["culture"] === 670 || dataPodiumCA_2035_2D["1"][0]["culture"] === 675)? Viti : dataPodiumCA_2035_2D["1"][0]["culture"] === 221 ? Tournesol : dataPodiumCA_2035_2D["1"][0]["culture"] === 213 ? Betterave : dataPodiumCA_2035_2D["1"][0]["culture"] === 202 ? PdT : dataPodiumCA_2035_2D["1"][0]["culture"] === 323 ? Luzerne : dataPodiumCA_2035_2D["1"][0]["culture"] === 231 ? Chanvre : dataPodiumCA_2035_2D["1"][0]["culture"] === 223 ? Soja : dataPodiumCA_2035_2D["1"][0]["culture"] === 2142 ||  dataPodiumCA_2035_2D["1"][0]["culture"] === 2143 ? Feverole : dataPodiumCA_2035_2D["1"][0]["culture"] === 2172 ||  dataPodiumCA_2035_2D["1"][0]["culture"] === 2173 ? Pois :  ''} alt='icone'  className="imageAleaPodiumRapport" />
            </div> 
          </div>
          <div className='data_tableau_podium_rapport_entete'> 
            <div className='row'>
                <img src={dataPodiumCA_2035_2D["2"][0]["alea"] === 'SH' ? SH_ac :dataPodiumCA_2035_2D["2"][0]["alea"] === 'EE' ? EE_ac :dataPodiumCA_2035_2D["2"][0]["alea"] === 'CDC' ? CDC_ac :dataPodiumCA_2035_2D["2"][0]["alea"] === 'GEL' ? GEL_ac :''} alt='icone'  className="imageAleaPodiumRapport" />
                <img src={dataPodiumCA_2035_2D["2"][0]["culture"] === 1112 ||  dataPodiumCA_2035_2D["2"][0]["culture"] === 1113 ? BleT : dataPodiumCA_2035_2D["2"][0]["culture"] === 1122 ||  dataPodiumCA_2035_2D["2"][0]["culture"] === 1123 ? BleD : dataPodiumCA_2035_2D["2"][0]["culture"] === 114 ||  dataPodiumCA_2035_2D["2"][0]["culture"] === 115 ? Orge : dataPodiumCA_2035_2D["2"][0]["culture"] === 118 ? Mais : dataPodiumCA_2035_2D["2"][0]["culture"] === 222 ? Colza : (dataPodiumCA_2035_2D["2"][0]["culture"] === 630 || dataPodiumCA_2035_2D["2"][0]["culture"] === 670 || dataPodiumCA_2035_2D["2"][0]["culture"] === 675)? Viti : dataPodiumCA_2035_2D["2"][0]["culture"] === 221 ? Tournesol : dataPodiumCA_2035_2D["2"][0]["culture"] === 213 ? Betterave : dataPodiumCA_2035_2D["2"][0]["culture"] === 202 ? PdT : dataPodiumCA_2035_2D["2"][0]["culture"] === 323 ? Luzerne : dataPodiumCA_2035_2D["2"][0]["culture"] === 231 ? Chanvre : dataPodiumCA_2035_2D["2"][0]["culture"] === 223 ? Soja : dataPodiumCA_2035_2D["2"][0]["culture"] === 2142 ||  dataPodiumCA_2035_2D["2"][0]["culture"] === 2143 ? Feverole : dataPodiumCA_2035_2D["2"][0]["culture"] === 2172 ||  dataPodiumCA_2035_2D["2"][0]["culture"] === 2173 ? Pois :  ''} alt='icone'  className="imageAleaPodiumRapport" />
            </div> 
          </div>
          <div className='data_tableau_podium_rapport_entete'> 
            <div className='row'>
                <img src={dataPodiumCA_2035_2D["3"][0]["alea"] === 'SH' ? SH_ac :dataPodiumCA_2035_2D["3"][0]["alea"] === 'EE' ? EE_ac :dataPodiumCA_2035_2D["3"][0]["alea"] === 'CDC' ? CDC_ac :dataPodiumCA_2035_2D["3"][0]["alea"] === 'GEL' ? GEL_ac :''} alt='icone'  className="imageAleaPodiumRapport" />
                <img src={dataPodiumCA_2035_2D["3"][0]["culture"] === 1112 ||  dataPodiumCA_2035_2D["3"][0]["culture"] === 1113 ? BleT : dataPodiumCA_2035_2D["3"][0]["culture"] === 1122 ||  dataPodiumCA_2035_2D["3"][0]["culture"] === 1123 ? BleD : dataPodiumCA_2035_2D["3"][0]["culture"] === 114 ||  dataPodiumCA_2035_2D["3"][0]["culture"] === 115 ? Orge : dataPodiumCA_2035_2D["3"][0]["culture"] === 118 ? Mais : dataPodiumCA_2035_2D["3"][0]["culture"] === 222 ? Colza : (dataPodiumCA_2035_2D["3"][0]["culture"] === 630 || dataPodiumCA_2035_2D["3"][0]["culture"] === 670 || dataPodiumCA_2035_2D["3"][0]["culture"] === 675)? Viti : dataPodiumCA_2035_2D["3"][0]["culture"] === 221 ? Tournesol : dataPodiumCA_2035_2D["3"][0]["culture"] === 213 ? Betterave : dataPodiumCA_2035_2D["3"][0]["culture"] === 202 ? PdT : dataPodiumCA_2035_2D["3"][0]["culture"] === 323 ? Luzerne : dataPodiumCA_2035_2D["3"][0]["culture"] === 231 ? Chanvre : dataPodiumCA_2035_2D["3"][0]["culture"] === 223 ? Soja : dataPodiumCA_2035_2D["3"][0]["culture"] === 2142 ||  dataPodiumCA_2035_2D["3"][0]["culture"] === 2143 ? Feverole : dataPodiumCA_2035_2D["3"][0]["culture"] === 2172 ||  dataPodiumCA_2035_2D["3"][0]["culture"] === 2173 ? Pois :  ''} alt='icone'  className="imageAleaPodiumRapport" />
            </div> 
          </div>
        </div>
        <div className='row_tableau_prix_rapport'>
          <div className='data_tableau_podium_rapport' style={{height:'60px'}}> 
            <div style={{textAlign:'center'}}> 
              {dataPodiumCA_2035_2D["1"][0]["alea"] === 'SH' ? "Stress hydrique" : dataPodiumCA_2035_2D["1"][0]["alea"] === 'EE' ? "Excès d'eau" : dataPodiumCA_2035_2D["1"][0]["alea"] === 'CDC' ? 'Coup de chaleur' : dataPodiumCA_2035_2D["1"][0]["alea"] === 'GEL' ? "Gel" : ''} / 
              {dataPodiumCA_2035_2D["1"][0]["culture"] === 1112 ? "Blé tendre de printemps" : dataPodiumCA_2035_2D["1"][0]["culture"] === 1113 ? "Blé tendre d'hiver" : dataPodiumCA_2035_2D["1"][0]["culture"] === 1122 ? "Blé dur de printemps" :  dataPodiumCA_2035_2D["1"][0]["culture"] === 1123 ? "Blé dur d'hiver" : dataPodiumCA_2035_2D["1"][0]["culture"] === 114 ? "Orge de printemps"  : dataPodiumCA_2035_2D["1"][0]["culture"] === 115 ? "Orge d'hiver" : dataPodiumCA_2035_2D["1"][0]["culture"] === 118 ? "Maïs" : dataPodiumCA_2035_2D["1"][0]["culture"] === 222 ? "Colza" : dataPodiumCA_2035_2D["1"][0]["culture"] === 630 ? "Viticulture AOC" : dataPodiumCA_2035_2D["1"][0]["culture"] === 670 ? "Viticulture IGP" : dataPodiumCA_2035_2D["1"][0]["culture"] === 675 ? "Viticulture VSIG" : dataPodiumCA_2035_2D["1"][0]["culture"] === 221 ? "Tournesol" : dataPodiumCA_2035_2D["1"][0]["culture"] === 213 ? "Betterave sucrière" : dataPodiumCA_2035_2D["1"][0]["culture"] === 202 ? "Pomme de terre" : dataPodiumCA_2035_2D["1"][0]["culture"] === 323 ? "Luzerne" : dataPodiumCA_2035_2D["1"][0]["culture"] === 231 ? "Chanvre" : dataPodiumCA_2035_2D["1"][0]["culture"] === 223 ? "Soja" : dataPodiumCA_2035_2D["1"][0]["culture"] === 2142 ? "Feverole de printemps" :  dataPodiumCA_2035_2D["1"][0]["culture"] === 2143 ? "Feverole d'hiver" : dataPodiumCA_2035_2D["1"][0]["culture"] === 2172 ? "Pois protéagineux de printemps" :  dataPodiumCA_2035_2D["1"][0]["culture"] === 2173 ?  "Pois protéagineux d'hiver" :  ''} 
              </div>
          </div>
          <div className='data_tableau_podium_rapport' style={{height:'60px'}}> 
            <div style={{textAlign:'center'}}> 
              {dataPodiumCA_2035_2D["2"][0]["alea"] === 'SH' ? "Stress hydrique" : dataPodiumCA_2035_2D["2"][0]["alea"] === 'EE' ? "Excès d'eau" : dataPodiumCA_2035_2D["2"][0]["alea"] === 'CDC' ? 'Coup de chaleur' : dataPodiumCA_2035_2D["2"][0]["alea"] === 'GEL' ? "Gel" : ''} / 
              {dataPodiumCA_2035_2D["2"][0]["culture"] === 1112 ? "Blé tendre de printemps" : dataPodiumCA_2035_2D["2"][0]["culture"] === 1113 ? "Blé tendre d'hiver" : dataPodiumCA_2035_2D["2"][0]["culture"] === 1122 ? "Blé dur de printemps" :  dataPodiumCA_2035_2D["2"][0]["culture"] === 1123 ? "Blé dur d'hiver" : dataPodiumCA_2035_2D["2"][0]["culture"] === 114 ? "Orge de printemps"  : dataPodiumCA_2035_2D["2"][0]["culture"] === 115 ? "Orge d'hiver" : dataPodiumCA_2035_2D["2"][0]["culture"] === 118 ? "Maïs" : dataPodiumCA_2035_2D["2"][0]["culture"] === 222 ? "Colza" : dataPodiumCA_2035_2D["2"][0]["culture"] === 630 ? "Viticulture AOC" : dataPodiumCA_2035_2D["2"][0]["culture"] === 670 ? "Viticulture IGP" : dataPodiumCA_2035_2D["2"][0]["culture"] === 675 ? "Viticulture VSIG" : dataPodiumCA_2035_2D["2"][0]["culture"] === 221 ? "Tournesol" : dataPodiumCA_2035_2D["2"][0]["culture"] === 213 ? "Betterave sucrière" : dataPodiumCA_2035_2D["2"][0]["culture"] === 202 ? "Pomme de terre" : dataPodiumCA_2035_2D["2"][0]["culture"] === 323 ? "Luzerne" : dataPodiumCA_2035_2D["2"][0]["culture"] === 231 ? "Chanvre" : dataPodiumCA_2035_2D["2"][0]["culture"] === 223 ? "Soja" : dataPodiumCA_2035_2D["2"][0]["culture"] === 2142 ? "Feverole de printemps" :  dataPodiumCA_2035_2D["2"][0]["culture"] === 2143 ? "Feverole d'hiver" : dataPodiumCA_2035_2D["2"][0]["culture"] === 2172 ? "Pois protéagineux de printemps" :  dataPodiumCA_2035_2D["2"][0]["culture"] === 2173 ?  "Pois protéagineux d'hiver" :  ''} 
              </div>
          </div>
          <div className='data_tableau_podium_rapport' style={{height:'60px'}}> 
            <div style={{textAlign:'center'}}> 
              {dataPodiumCA_2035_2D["3"][0]["alea"] === 'SH' ? "Stress hydrique" : dataPodiumCA_2035_2D["3"][0]["alea"] === 'EE' ? "Excès d'eau" : dataPodiumCA_2035_2D["3"][0]["alea"] === 'CDC' ? 'Coup de chaleur' : dataPodiumCA_2035_2D["3"][0]["alea"] === 'GEL' ? "Gel" : ''} / 
              {dataPodiumCA_2035_2D["3"][0]["culture"] === 1112 ? "Blé tendre de printemps" : dataPodiumCA_2035_2D["3"][0]["culture"] === 1113 ? "Blé tendre d'hiver" : dataPodiumCA_2035_2D["3"][0]["culture"] === 1122 ? "Blé dur de printemps" :  dataPodiumCA_2035_2D["3"][0]["culture"] === 1123 ? "Blé dur d'hiver" : dataPodiumCA_2035_2D["3"][0]["culture"] === 114 ? "Orge de printemps"  : dataPodiumCA_2035_2D["3"][0]["culture"] === 115 ? "Orge d'hiver" : dataPodiumCA_2035_2D["3"][0]["culture"] === 118 ? "Maïs" : dataPodiumCA_2035_2D["3"][0]["culture"] === 222 ? "Colza" : dataPodiumCA_2035_2D["3"][0]["culture"] === 630 ? "Viticulture AOC" : dataPodiumCA_2035_2D["3"][0]["culture"] === 670 ? "Viticulture IGP" : dataPodiumCA_2035_2D["3"][0]["culture"] === 675 ? "Viticulture VSIG" : dataPodiumCA_2035_2D["3"][0]["culture"] === 221 ? "Tournesol" : dataPodiumCA_2035_2D["3"][0]["culture"] === 213 ? "Betterave sucrière" : dataPodiumCA_2035_2D["3"][0]["culture"] === 202 ? "Pomme de terre" : dataPodiumCA_2035_2D["3"][0]["culture"] === 323 ? "Luzerne" : dataPodiumCA_2035_2D["3"][0]["culture"] === 231 ? "Chanvre" : dataPodiumCA_2035_2D["3"][0]["culture"] === 223 ? "Soja" : dataPodiumCA_2035_2D["3"][0]["culture"] === 2142 ? "Feverole de printemps" :  dataPodiumCA_2035_2D["3"][0]["culture"] === 2143 ? "Feverole d'hiver" : dataPodiumCA_2035_2D["3"][0]["culture"] === 2172 ? "Pois protéagineux de printemps" :  dataPodiumCA_2035_2D["3"][0]["culture"] === 2173 ?  "Pois protéagineux d'hiver" :  ''} 
              </div>
          </div>
        </div>
        <div className='row_tableau_prix_rapport'>
          <div className='data_tableau_podium_rapport color-tab-podium-35-2D' style={{height:'30px'}}> {dataPodiumCA_2035_2D["1"][0]["perte"]} % </div>
          <div className='data_tableau_podium_rapport color-tab-podium-35-2D' style={{height:'30px'}}> {dataPodiumCA_2035_2D['2']!== undefined ?dataPodiumCA_2035_2D["2"][0]["perte"]: "/"} % </div>
          <div className='data_tableau_podium_rapport color-tab-podium-35-2D' style={{height:'30px'}}> {dataPodiumCA_2035_2D["3"] !== undefined ? dataPodiumCA_2035_2D["3"][0]["perte"] : "/"} % </div>
        </div>
      </div> 
      : ""}

      {dataPodiumCA_2035_4D !== undefined ?
      <div className='tableau_podium_rapport'> 
        <div className='row_tableau_prix_rapport'>
          <div className='data_tableau_podium_rapport  color-tab-podium-35-4D' style={{width:'99.3%',height:'30px' ,fontWeight:'bold'}}>2035  avec un réchauffement à +4°C </div>
        </div>
        <div className='row_tableau_prix_rapport'>
          <div className='data_tableau_podium_rapport_entete'> <img src={podium1} className='imageClassementPodium' alt="première place" /> </div>
          <div className='data_tableau_podium_rapport_entete'> <img src={podium2} className='imageClassementPodium' alt="deuxième place" /> </div>
          <div className='data_tableau_podium_rapport_entete'> <img src={podium3} className='imageClassementPodium' alt="troisème place" /> </div>
        </div>
        <div className='row_tableau_prix_rapport'>
          <div className='data_tableau_podium_rapport_entete'> 
            <div className='row'>
                <img src={dataPodiumCA_2035_4D["1"][0]["alea"] === 'SH' ? SH_ac :dataPodiumCA_2035_4D["1"][0]["alea"] === 'EE' ? EE_ac :dataPodiumCA_2035_4D["1"][0]["alea"] === 'CDC' ? CDC_ac :dataPodiumCA_2035_4D["1"][0]["alea"] === 'GEL' ? GEL_ac :''} alt='icone'  className="imageAleaPodiumRapport" />
                <img src={dataPodiumCA_2035_4D["1"][0]["culture"] === 1112 ||  dataPodiumCA_2035_4D["1"][0]["culture"] === 1113 ? BleT : dataPodiumCA_2035_4D["1"][0]["culture"] === 1122 ||  dataPodiumCA_2035_4D["1"][0]["culture"] === 1123 ? BleD : dataPodiumCA_2035_4D["1"][0]["culture"] === 114 ||  dataPodiumCA_2035_4D["1"][0]["culture"] === 115 ? Orge : dataPodiumCA_2035_4D["1"][0]["culture"] === 118 ? Mais : dataPodiumCA_2035_4D["1"][0]["culture"] === 222 ? Colza : (dataPodiumCA_2035_4D["1"][0]["culture"] === 630 || dataPodiumCA_2035_4D["1"][0]["culture"] === 670 || dataPodiumCA_2035_4D["1"][0]["culture"] === 675)? Viti : dataPodiumCA_2035_4D["1"][0]["culture"] === 221 ? Tournesol : dataPodiumCA_2035_4D["1"][0]["culture"] === 213 ? Betterave : dataPodiumCA_2035_4D["1"][0]["culture"] === 202 ? PdT : dataPodiumCA_2035_4D["1"][0]["culture"] === 323 ? Luzerne : dataPodiumCA_2035_4D["1"][0]["culture"] === 231 ? Chanvre : dataPodiumCA_2035_4D["1"][0]["culture"] === 223 ? Soja : dataPodiumCA_2035_4D["1"][0]["culture"] === 2142 ||  dataPodiumCA_2035_4D["1"][0]["culture"] === 2143 ? Feverole : dataPodiumCA_2035_4D["1"][0]["culture"] === 2172 ||  dataPodiumCA_2035_4D["1"][0]["culture"] === 2173 ? Pois :  ''} alt='icone'  className="imageAleaPodiumRapport" />
            </div> 
          </div>
          <div className='data_tableau_podium_rapport_entete'> 
            <div className='row'>
                <img src={dataPodiumCA_2035_4D["2"][0]["alea"] === 'SH' ? SH_ac :dataPodiumCA_2035_4D["2"][0]["alea"] === 'EE' ? EE_ac :dataPodiumCA_2035_4D["2"][0]["alea"] === 'CDC' ? CDC_ac :dataPodiumCA_2035_4D["2"][0]["alea"] === 'GEL' ? GEL_ac :''} alt='icone'  className="imageAleaPodiumRapport" />
                <img src={dataPodiumCA_2035_4D["2"][0]["culture"] === 1112 ||  dataPodiumCA_2035_4D["2"][0]["culture"] === 1113 ? BleT : dataPodiumCA_2035_4D["2"][0]["culture"] === 1122 ||  dataPodiumCA_2035_4D["2"][0]["culture"] === 1123 ? BleD : dataPodiumCA_2035_4D["2"][0]["culture"] === 114 ||  dataPodiumCA_2035_4D["2"][0]["culture"] === 115 ? Orge : dataPodiumCA_2035_4D["2"][0]["culture"] === 118 ? Mais : dataPodiumCA_2035_4D["2"][0]["culture"] === 222 ? Colza : (dataPodiumCA_2035_4D["2"][0]["culture"] === 630 || dataPodiumCA_2035_4D["2"][0]["culture"] === 670 || dataPodiumCA_2035_4D["2"][0]["culture"] === 675)? Viti : dataPodiumCA_2035_4D["2"][0]["culture"] === 221 ? Tournesol : dataPodiumCA_2035_4D["2"][0]["culture"] === 213 ? Betterave : dataPodiumCA_2035_4D["2"][0]["culture"] === 202 ? PdT : dataPodiumCA_2035_4D["2"][0]["culture"] === 323 ? Luzerne : dataPodiumCA_2035_4D["2"][0]["culture"] === 231 ? Chanvre : dataPodiumCA_2035_4D["2"][0]["culture"] === 223 ? Soja : dataPodiumCA_2035_4D["2"][0]["culture"] === 2142 ||  dataPodiumCA_2035_4D["2"][0]["culture"] === 2143 ? Feverole : dataPodiumCA_2035_4D["2"][0]["culture"] === 2172 ||  dataPodiumCA_2035_4D["2"][0]["culture"] === 2173 ? Pois :  ''} alt='icone'  className="imageAleaPodiumRapport" />
            </div> 
          </div >
          <div className='data_tableau_podium_rapport_entete'> 
            <div className='row'>
                <img src={dataPodiumCA_2035_4D["3"][0]["alea"] === 'SH' ? SH_ac :dataPodiumCA_2035_4D["3"][0]["alea"] === 'EE' ? EE_ac :dataPodiumCA_2035_4D["3"][0]["alea"] === 'CDC' ? CDC_ac :dataPodiumCA_2035_4D["3"][0]["alea"] === 'GEL' ? GEL_ac :''} alt='icone'  className="imageAleaPodiumRapport" />
                <img src={dataPodiumCA_2035_4D["3"][0]["culture"] === 1112 ||  dataPodiumCA_2035_4D["3"][0]["culture"] === 1113 ? BleT : dataPodiumCA_2035_4D["3"][0]["culture"] === 1122 ||  dataPodiumCA_2035_4D["3"][0]["culture"] === 1123 ? BleD : dataPodiumCA_2035_4D["3"][0]["culture"] === 114 ||  dataPodiumCA_2035_4D["3"][0]["culture"] === 115 ? Orge : dataPodiumCA_2035_4D["3"][0]["culture"] === 118 ? Mais : dataPodiumCA_2035_4D["3"][0]["culture"] === 222 ? Colza : (dataPodiumCA_2035_4D["3"][0]["culture"] === 630 || dataPodiumCA_2035_4D["3"][0]["culture"] === 670 || dataPodiumCA_2035_4D["3"][0]["culture"] === 675)? Viti : dataPodiumCA_2035_4D["3"][0]["culture"] === 221 ? Tournesol : dataPodiumCA_2035_4D["3"][0]["culture"] === 213 ? Betterave : dataPodiumCA_2035_4D["3"][0]["culture"] === 202 ? PdT : dataPodiumCA_2035_4D["3"][0]["culture"] === 323 ? Luzerne : dataPodiumCA_2035_4D["3"][0]["culture"] === 231 ? Chanvre : dataPodiumCA_2035_4D["3"][0]["culture"] === 223 ? Soja : dataPodiumCA_2035_4D["3"][0]["culture"] === 2142 ||  dataPodiumCA_2035_4D["3"][0]["culture"] === 2143 ? Feverole : dataPodiumCA_2035_4D["3"][0]["culture"] === 2172 ||  dataPodiumCA_2035_4D["3"][0]["culture"] === 2173 ? Pois :  ''} alt='icone'  className="imageAleaPodiumRapport" />
            </div> 
          </div>
        </div>
        <div className='row_tableau_prix_rapport'>
          <div className='data_tableau_podium_rapport' style={{height:'60px'}}> 
            <div style={{textAlign:'center'}}> 
              {dataPodiumCA_2035_4D["1"][0]["alea"] === 'SH' ? "Stress hydrique" : dataPodiumCA_2035_4D["1"][0]["alea"] === 'EE' ? "Excès d'eau" : dataPodiumCA_2035_4D["1"][0]["alea"] === 'CDC' ? 'Coup de chaleur' : dataPodiumCA_2035_4D["1"][0]["alea"] === 'GEL' ? "Gel" : ''} / 
              {dataPodiumCA_2035_4D["1"][0]["culture"] === 1112 ? "Blé tendre de printemps" : dataPodiumCA_2035_4D["1"][0]["culture"] === 1113 ? "Blé tendre d'hiver" : dataPodiumCA_2035_4D["1"][0]["culture"] === 1122 ? "Blé dur de printemps" :  dataPodiumCA_2035_4D["1"][0]["culture"] === 1123 ? "Blé dur d'hiver" : dataPodiumCA_2035_4D["1"][0]["culture"] === 114 ? "Orge de printemps"  : dataPodiumCA_2035_4D["1"][0]["culture"] === 115 ? "Orge d'hiver" : dataPodiumCA_2035_4D["1"][0]["culture"] === 118 ? "Maïs" : dataPodiumCA_2035_4D["1"][0]["culture"] === 222 ? "Colza" : dataPodiumCA_2035_4D["1"][0]["culture"] === 630 ? "Viticulture AOC" : dataPodiumCA_2035_4D["1"][0]["culture"] === 670 ? "Viticulture IGP" : dataPodiumCA_2035_4D["1"][0]["culture"] === 675 ? "Viticulture VSIG" : dataPodiumCA_2035_4D["1"][0]["culture"] === 221 ? "Tournesol" : dataPodiumCA_2035_4D["1"][0]["culture"] === 213 ? "Betterave sucrière" : dataPodiumCA_2035_4D["1"][0]["culture"] === 202 ? "Pomme de terre" : dataPodiumCA_2035_4D["1"][0]["culture"] === 323 ? "Luzerne" : dataPodiumCA_2035_4D["1"][0]["culture"] === 231 ? "Chanvre" : dataPodiumCA_2035_4D["1"][0]["culture"] === 223 ? "Soja" : dataPodiumCA_2035_4D["1"][0]["culture"] === 2142 ? "Feverole de printemps" :  dataPodiumCA_2035_4D["1"][0]["culture"] === 2143 ? "Feverole d'hiver" : dataPodiumCA_2035_4D["1"][0]["culture"] === 2172 ? "Pois protéagineux de printemps" :  dataPodiumCA_2035_4D["1"][0]["culture"] === 2173 ?  "Pois protéagineux d'hiver" :  ''} 
              </div>
          </div>
          <div className='data_tableau_podium_rapport' style={{height:'60px'}}> 
            <div style={{textAlign:'center'}}> 
              {dataPodiumCA_2035_4D["2"][0]["alea"] === 'SH' ? "Stress hydrique" : dataPodiumCA_2035_4D["2"][0]["alea"] === 'EE' ? "Excès d'eau" : dataPodiumCA_2035_4D["2"][0]["alea"] === 'CDC' ? 'Coup de chaleur' : dataPodiumCA_2035_4D["2"][0]["alea"] === 'GEL' ? "Gel" : ''} / 
              {dataPodiumCA_2035_4D["2"][0]["culture"] === 1112 ? "Blé tendre de printemps" : dataPodiumCA_2035_4D["2"][0]["culture"] === 1113 ? "Blé tendre d'hiver" : dataPodiumCA_2035_4D["2"][0]["culture"] === 1122 ? "Blé dur de printemps" :  dataPodiumCA_2035_4D["2"][0]["culture"] === 1123 ? "Blé dur d'hiver" : dataPodiumCA_2035_4D["2"][0]["culture"] === 114 ? "Orge de printemps"  : dataPodiumCA_2035_4D["2"][0]["culture"] === 115 ? "Orge d'hiver" : dataPodiumCA_2035_4D["2"][0]["culture"] === 118 ? "Maïs" : dataPodiumCA_2035_4D["2"][0]["culture"] === 222 ? "Colza" : dataPodiumCA_2035_4D["2"][0]["culture"] === 630 ? "Viticulture AOC" : dataPodiumCA_2035_4D["2"][0]["culture"] === 670 ? "Viticulture IGP" : dataPodiumCA_2035_4D["2"][0]["culture"] === 675 ? "Viticulture VSIG" : dataPodiumCA_2035_4D["2"][0]["culture"] === 221 ? "Tournesol" : dataPodiumCA_2035_4D["2"][0]["culture"] === 213 ? "Betterave sucrière" : dataPodiumCA_2035_4D["2"][0]["culture"] === 202 ? "Pomme de terre" : dataPodiumCA_2035_4D["2"][0]["culture"] === 323 ? "Luzerne" : dataPodiumCA_2035_4D["2"][0]["culture"] === 231 ? "Chanvre" : dataPodiumCA_2035_4D["2"][0]["culture"] === 223 ? "Soja" : dataPodiumCA_2035_4D["2"][0]["culture"] === 2142 ? "Feverole de printemps" :  dataPodiumCA_2035_4D["2"][0]["culture"] === 2143 ? "Feverole d'hiver" : dataPodiumCA_2035_4D["2"][0]["culture"] === 2172 ? "Pois protéagineux de printemps" :  dataPodiumCA_2035_4D["2"][0]["culture"] === 2173 ?  "Pois protéagineux d'hiver" :  ''} 
              </div>
          </div>
          <div className='data_tableau_podium_rapport' style={{height:'60px'}}> 
            <div style={{textAlign:'center'}}> 
              {dataPodiumCA_2035_4D["3"][0]["alea"] === 'SH' ? "Stress hydrique" : dataPodiumCA_2035_4D["3"][0]["alea"] === 'EE' ? "Excès d'eau" : dataPodiumCA_2035_4D["3"][0]["alea"] === 'CDC' ? 'Coup de chaleur' : dataPodiumCA_2035_4D["3"][0]["alea"] === 'GEL' ? "Gel" : ''} / 
              {dataPodiumCA_2035_4D["3"][0]["culture"] === 1112 ? "Blé tendre de printemps" : dataPodiumCA_2035_4D["3"][0]["culture"] === 1113 ? "Blé tendre d'hiver" : dataPodiumCA_2035_4D["3"][0]["culture"] === 1122 ? "Blé dur de printemps" :  dataPodiumCA_2035_4D["3"][0]["culture"] === 1123 ? "Blé dur d'hiver" : dataPodiumCA_2035_4D["3"][0]["culture"] === 114 ? "Orge de printemps"  : dataPodiumCA_2035_4D["3"][0]["culture"] === 115 ? "Orge d'hiver" : dataPodiumCA_2035_4D["3"][0]["culture"] === 118 ? "Maïs" : dataPodiumCA_2035_4D["3"][0]["culture"] === 222 ? "Colza" : dataPodiumCA_2035_4D["3"][0]["culture"] === 630 ? "Viticulture AOC" : dataPodiumCA_2035_4D["3"][0]["culture"] === 670 ? "Viticulture IGP" : dataPodiumCA_2035_4D["3"][0]["culture"] === 675 ? "Viticulture VSIG" : dataPodiumCA_2035_4D["3"][0]["culture"] === 221 ? "Tournesol" : dataPodiumCA_2035_4D["3"][0]["culture"] === 213 ? "Betterave sucrière" : dataPodiumCA_2035_4D["3"][0]["culture"] === 202 ? "Pomme de terre" : dataPodiumCA_2035_4D["3"][0]["culture"] === 323 ? "Luzerne" : dataPodiumCA_2035_4D["3"][0]["culture"] === 231 ? "Chanvre" : dataPodiumCA_2035_4D["3"][0]["culture"] === 223 ? "Soja" : dataPodiumCA_2035_4D["3"][0]["culture"] === 2142 ? "Feverole de printemps" :  dataPodiumCA_2035_4D["3"][0]["culture"] === 2143 ? "Feverole d'hiver" : dataPodiumCA_2035_4D["3"][0]["culture"] === 2172 ? "Pois protéagineux de printemps" :  dataPodiumCA_2035_4D["3"][0]["culture"] === 2173 ?  "Pois protéagineux d'hiver" :  ''} 
              </div>
          </div>
        </div>
        <div className='row_tableau_prix_rapport'>
          <div className='data_tableau_podium_rapport color-tab-podium-35-4D' style={{height:'30px'}}> {dataPodiumCA_2035_4D["1"][0]["perte"]} % </div>
          <div className='data_tableau_podium_rapport color-tab-podium-35-4D' style={{height:'30px'}}> {dataPodiumCA_2035_4D['2']!== undefined ?dataPodiumCA_2035_4D["2"][0]["perte"]: "/"} % </div>
          <div className='data_tableau_podium_rapport color-tab-podium-35-4D' style={{height:'30px'}}> {dataPodiumCA_2035_4D["3"] !== undefined ? dataPodiumCA_2035_4D["3"][0]["perte"] : "/"} % </div>
        </div>
      </div>
      : ""}

      {dataPodiumCA_2050_2D !== undefined ?
      <div className='tableau_podium_rapport'> 
        <div className='row_tableau_prix_rapport'>
          <div className='data_tableau_podium_rapport  color-tab-podium-50-2D' style={{width:'99.3%',height:'30px' ,fontWeight:'bold'}}>2050 avec un réchauffement à +2°C </div>
        </div>
        <div className='row_tableau_prix_rapport'>
          <div className='data_tableau_podium_rapport_entete'> <img src={podium1} className='imageClassementPodium' alt="première place" /> </div>
          <div className='data_tableau_podium_rapport_entete'> <img src={podium2} className='imageClassementPodium' alt="deuxième place" /> </div>
          <div className='data_tableau_podium_rapport_entete'> <img src={podium3} className='imageClassementPodium' alt="troisième place" /> </div>
        </div>
        <div className='row_tableau_prix_rapport'>
          <div className='data_tableau_podium_rapport_entete'> 
            <div className='row'>
                <img src={dataPodiumCA_2050_2D["1"][0]["alea"] === 'SH' ? SH_ac :dataPodiumCA_2050_2D["1"][0]["alea"] === 'EE' ? EE_ac :dataPodiumCA_2050_2D["1"][0]["alea"] === 'CDC' ? CDC_ac :dataPodiumCA_2050_2D["1"][0]["alea"] === 'GEL' ? GEL_ac :''} alt='icone'  className="imageAleaPodiumRapport" />
                <img src={dataPodiumCA_2050_2D["1"][0]["culture"] === 1112 ||  dataPodiumCA_2050_2D["1"][0]["culture"] === 1113 ? BleT : dataPodiumCA_2050_2D["1"][0]["culture"] === 1122 ||  dataPodiumCA_2050_2D["1"][0]["culture"] === 1123 ? BleD : dataPodiumCA_2050_2D["1"][0]["culture"] === 114 ||  dataPodiumCA_2050_2D["1"][0]["culture"] === 115 ? Orge : dataPodiumCA_2050_2D["1"][0]["culture"] === 118 ? Mais : dataPodiumCA_2050_2D["1"][0]["culture"] === 222 ? Colza : (dataPodiumCA_2050_2D["1"][0]["culture"] === 630 || dataPodiumCA_2050_2D["1"][0]["culture"] === 670 || dataPodiumCA_2050_2D["1"][0]["culture"] === 675)? Viti : dataPodiumCA_2050_2D["1"][0]["culture"] === 221 ? Tournesol : dataPodiumCA_2050_2D["1"][0]["culture"] === 213 ? Betterave : dataPodiumCA_2050_2D["1"][0]["culture"] === 202 ? PdT : dataPodiumCA_2050_2D["1"][0]["culture"] === 323 ? Luzerne : dataPodiumCA_2050_2D["1"][0]["culture"] === 231 ? Chanvre : dataPodiumCA_2050_2D["1"][0]["culture"] === 223 ? Soja : dataPodiumCA_2050_2D["1"][0]["culture"] === 2142 ||  dataPodiumCA_2050_2D["1"][0]["culture"] === 2143 ? Feverole : dataPodiumCA_2050_2D["1"][0]["culture"] === 2172 ||  dataPodiumCA_2050_2D["1"][0]["culture"] === 2173 ? Pois :  ''} alt='icone'  className="imageAleaPodiumRapport" />
            </div> 
          </div>
          <div className='data_tableau_podium_rapport_entete'> 
            <div className='row'>
                <img src={dataPodiumCA_2050_2D["2"][0]["alea"] === 'SH' ? SH_ac :dataPodiumCA_2050_2D["2"][0]["alea"] === 'EE' ? EE_ac :dataPodiumCA_2050_2D["2"][0]["alea"] === 'CDC' ? CDC_ac :dataPodiumCA_2050_2D["2"][0]["alea"] === 'GEL' ? GEL_ac :''} alt='icone'  className="imageAleaPodiumRapport" />
                <img src={dataPodiumCA_2050_2D["2"][0]["culture"] === 1112 ||  dataPodiumCA_2050_2D["2"][0]["culture"] === 1113 ? BleT : dataPodiumCA_2050_2D["2"][0]["culture"] === 1122 ||  dataPodiumCA_2050_2D["2"][0]["culture"] === 1123 ? BleD : dataPodiumCA_2050_2D["2"][0]["culture"] === 114 ||  dataPodiumCA_2050_2D["2"][0]["culture"] === 115 ? Orge : dataPodiumCA_2050_2D["2"][0]["culture"] === 118 ? Mais : dataPodiumCA_2050_2D["2"][0]["culture"] === 222 ? Colza : (dataPodiumCA_2050_2D["2"][0]["culture"] === 630 || dataPodiumCA_2050_2D["2"][0]["culture"] === 670 || dataPodiumCA_2050_2D["2"][0]["culture"] === 675)? Viti : dataPodiumCA_2050_2D["2"][0]["culture"] === 221 ? Tournesol : dataPodiumCA_2050_2D["2"][0]["culture"] === 213 ? Betterave : dataPodiumCA_2050_2D["2"][0]["culture"] === 202 ? PdT : dataPodiumCA_2050_2D["2"][0]["culture"] === 323 ? Luzerne : dataPodiumCA_2050_2D["2"][0]["culture"] === 231 ? Chanvre : dataPodiumCA_2050_2D["2"][0]["culture"] === 223 ? Soja : dataPodiumCA_2050_2D["2"][0]["culture"] === 2142 ||  dataPodiumCA_2050_2D["2"][0]["culture"] === 2143 ? Feverole : dataPodiumCA_2050_2D["2"][0]["culture"] === 2172 ||  dataPodiumCA_2050_2D["2"][0]["culture"] === 2173 ? Pois :  ''} alt='icone'  className="imageAleaPodiumRapport" />
            </div> 
          </div>
          <div className='data_tableau_podium_rapport_entete'> 
            <div className='row'>
                <img src={dataPodiumCA_2050_2D["3"][0]["alea"] === 'SH' ? SH_ac :dataPodiumCA_2050_2D["3"][0]["alea"] === 'EE' ? EE_ac :dataPodiumCA_2050_2D["3"][0]["alea"] === 'CDC' ? CDC_ac :dataPodiumCA_2050_2D["3"][0]["alea"] === 'GEL' ? GEL_ac :''} alt='icone'  className="imageAleaPodiumRapport" />
                <img src={dataPodiumCA_2050_2D["3"][0]["culture"] === 1112 ||  dataPodiumCA_2050_2D["3"][0]["culture"] === 1113 ? BleT : dataPodiumCA_2050_2D["3"][0]["culture"] === 1122 ||  dataPodiumCA_2050_2D["3"][0]["culture"] === 1123 ? BleD : dataPodiumCA_2050_2D["3"][0]["culture"] === 114 ||  dataPodiumCA_2050_2D["3"][0]["culture"] === 115 ? Orge : dataPodiumCA_2050_2D["3"][0]["culture"] === 118 ? Mais : dataPodiumCA_2050_2D["3"][0]["culture"] === 222 ? Colza : (dataPodiumCA_2050_2D["3"][0]["culture"] === 630 || dataPodiumCA_2050_2D["3"][0]["culture"] === 670 || dataPodiumCA_2050_2D["3"][0]["culture"] === 675)? Viti : dataPodiumCA_2050_2D["3"][0]["culture"] === 221 ? Tournesol : dataPodiumCA_2050_2D["3"][0]["culture"] === 213 ? Betterave : dataPodiumCA_2050_2D["3"][0]["culture"] === 202 ? PdT : dataPodiumCA_2050_2D["3"][0]["culture"] === 323 ? Luzerne : dataPodiumCA_2050_2D["3"][0]["culture"] === 231 ? Chanvre : dataPodiumCA_2050_2D["3"][0]["culture"] === 223 ? Soja : dataPodiumCA_2050_2D["3"][0]["culture"] === 2142 ||  dataPodiumCA_2050_2D["3"][0]["culture"] === 2143 ? Feverole : dataPodiumCA_2050_2D["3"][0]["culture"] === 2172 ||  dataPodiumCA_2050_2D["3"][0]["culture"] === 2173 ? Pois :  ''} alt='icone'  className="imageAleaPodiumRapport" />
            </div> 
          </div>
        </div>
        <div className='row_tableau_prix_rapport'>
          <div className='data_tableau_podium_rapport' style={{height:'60px'}}> 
            <div style={{textAlign:'center'}}> 
              {dataPodiumCA_2050_2D["1"][0]["alea"] === 'SH' ? "Stress hydrique" : dataPodiumCA_2050_2D["1"][0]["alea"] === 'EE' ? "Excès d'eau" : dataPodiumCA_2050_2D["1"][0]["alea"] === 'CDC' ? 'Coup de chaleur' : dataPodiumCA_2050_2D["1"][0]["alea"] === 'GEL' ? "Gel" : ''} / 
              {dataPodiumCA_2050_2D["1"][0]["culture"] === 1112 ? "Blé tendre de printemps" : dataPodiumCA_2050_2D["1"][0]["culture"] === 1113 ? "Blé tendre d'hiver" : dataPodiumCA_2050_2D["1"][0]["culture"] === 1122 ? "Blé dur de printemps" :  dataPodiumCA_2050_2D["1"][0]["culture"] === 1123 ? "Blé dur d'hiver" : dataPodiumCA_2050_2D["1"][0]["culture"] === 114 ? "Orge de printemps"  : dataPodiumCA_2050_2D["1"][0]["culture"] === 115 ? "Orge d'hiver" : dataPodiumCA_2050_2D["1"][0]["culture"] === 118 ? "Maïs" : dataPodiumCA_2050_2D["1"][0]["culture"] === 222 ? "Colza" : dataPodiumCA_2050_2D["1"][0]["culture"] === 630 ? "Viticulture AOC" : dataPodiumCA_2050_2D["1"][0]["culture"] === 670 ? "Viticulture IGP" : dataPodiumCA_2050_2D["1"][0]["culture"] === 675 ? "Viticulture VSIG" : dataPodiumCA_2050_2D["1"][0]["culture"] === 221 ? "Tournesol" : dataPodiumCA_2050_2D["1"][0]["culture"] === 213 ? "Betterave sucrière" : dataPodiumCA_2050_2D["1"][0]["culture"] === 202 ? "Pomme de terre" : dataPodiumCA_2050_2D["1"][0]["culture"] === 323 ? "Luzerne" : dataPodiumCA_2050_2D["1"][0]["culture"] === 231 ? "Chanvre" : dataPodiumCA_2050_2D["1"][0]["culture"] === 223 ? "Soja" : dataPodiumCA_2050_2D["1"][0]["culture"] === 2142 ? "Feverole de printemps" :  dataPodiumCA_2050_2D["1"][0]["culture"] === 2143 ? "Feverole d'hiver" : dataPodiumCA_2050_2D["1"][0]["culture"] === 2172 ? "Pois protéagineux de printemps" :  dataPodiumCA_2050_2D["1"][0]["culture"] === 2173 ?  "Pois protéagineux d'hiver" :  ''} 
              </div>
          </div>
          <div className='data_tableau_podium_rapport' style={{height:'60px'}}> 
            <div style={{textAlign:'center'}}> 
              {dataPodiumCA_2050_2D["2"][0]["alea"] === 'SH' ? "Stress hydrique" : dataPodiumCA_2050_2D["2"][0]["alea"] === 'EE' ? "Excès d'eau" : dataPodiumCA_2050_2D["2"][0]["alea"] === 'CDC' ? 'Coup de chaleur' : dataPodiumCA_2050_2D["2"][0]["alea"] === 'GEL' ? "Gel" : ''} / 
              {dataPodiumCA_2050_2D["2"][0]["culture"] === 1112 ? "Blé tendre de printemps" : dataPodiumCA_2050_2D["2"][0]["culture"] === 1113 ? "Blé tendre d'hiver" : dataPodiumCA_2050_2D["2"][0]["culture"] === 1122 ? "Blé dur de printemps" :  dataPodiumCA_2050_2D["2"][0]["culture"] === 1123 ? "Blé dur d'hiver" : dataPodiumCA_2050_2D["2"][0]["culture"] === 114 ? "Orge de printemps"  : dataPodiumCA_2050_2D["2"][0]["culture"] === 115 ? "Orge d'hiver" : dataPodiumCA_2050_2D["2"][0]["culture"] === 118 ? "Maïs" : dataPodiumCA_2050_2D["2"][0]["culture"] === 222 ? "Colza" : dataPodiumCA_2050_2D["2"][0]["culture"] === 630 ? "Viticulture AOC" : dataPodiumCA_2050_2D["2"][0]["culture"] === 670 ? "Viticulture IGP" : dataPodiumCA_2050_2D["2"][0]["culture"] === 675 ? "Viticulture VSIG" : dataPodiumCA_2050_2D["2"][0]["culture"] === 221 ? "Tournesol" : dataPodiumCA_2050_2D["2"][0]["culture"] === 213 ? "Betterave sucrière" : dataPodiumCA_2050_2D["2"][0]["culture"] === 202 ? "Pomme de terre" : dataPodiumCA_2050_2D["2"][0]["culture"] === 323 ? "Luzerne" : dataPodiumCA_2050_2D["2"][0]["culture"] === 231 ? "Chanvre" : dataPodiumCA_2050_2D["2"][0]["culture"] === 223 ? "Soja" : dataPodiumCA_2050_2D["2"][0]["culture"] === 2142 ? "Feverole de printemps" :  dataPodiumCA_2050_2D["2"][0]["culture"] === 2143 ? "Feverole d'hiver" : dataPodiumCA_2050_2D["2"][0]["culture"] === 2172 ? "Pois protéagineux de printemps" :  dataPodiumCA_2050_2D["2"][0]["culture"] === 2173 ?  "Pois protéagineux d'hiver" :  ''} 
              </div>
          </div>
          <div className='data_tableau_podium_rapport' style={{height:'60px'}}> 
            <div style={{textAlign:'center'}}>  
              {dataPodiumCA_2050_2D["3"][0]["alea"] === 'SH' ? "Stress hydrique" : dataPodiumCA_2050_2D["3"][0]["alea"] === 'EE' ? "Excès d'eau" : dataPodiumCA_2050_2D["3"][0]["alea"] === 'CDC' ? 'Coup de chaleur' : dataPodiumCA_2050_2D["3"][0]["alea"] === 'GEL' ? "Gel" : ''} / 
              {dataPodiumCA_2050_2D["3"][0]["culture"] === 1112 ? "Blé tendre de printemps" : dataPodiumCA_2050_2D["3"][0]["culture"] === 1113 ? "Blé tendre d'hiver" : dataPodiumCA_2050_2D["3"][0]["culture"] === 1122 ? "Blé dur de printemps" :  dataPodiumCA_2050_2D["3"][0]["culture"] === 1123 ? "Blé dur d'hiver" : dataPodiumCA_2050_2D["3"][0]["culture"] === 114 ? "Orge de printemps"  : dataPodiumCA_2050_2D["3"][0]["culture"] === 115 ? "Orge d'hiver" : dataPodiumCA_2050_2D["3"][0]["culture"] === 118 ? "Maïs" : dataPodiumCA_2050_2D["3"][0]["culture"] === 222 ? "Colza" : dataPodiumCA_2050_2D["3"][0]["culture"] === 630 ? "Viticulture AOC" : dataPodiumCA_2050_2D["3"][0]["culture"] === 670 ? "Viticulture IGP" : dataPodiumCA_2050_2D["3"][0]["culture"] === 675 ? "Viticulture VSIG" : dataPodiumCA_2050_2D["3"][0]["culture"] === 221 ? "Tournesol" : dataPodiumCA_2050_2D["3"][0]["culture"] === 213 ? "Betterave sucrière" : dataPodiumCA_2050_2D["3"][0]["culture"] === 202 ? "Pomme de terre" : dataPodiumCA_2050_2D["3"][0]["culture"] === 323 ? "Luzerne" : dataPodiumCA_2050_2D["3"][0]["culture"] === 231 ? "Chanvre" : dataPodiumCA_2050_2D["3"][0]["culture"] === 223 ? "Soja" : dataPodiumCA_2050_2D["3"][0]["culture"] === 2142 ? "Feverole de printemps" :  dataPodiumCA_2050_2D["3"][0]["culture"] === 2143 ? "Feverole d'hiver" : dataPodiumCA_2050_2D["3"][0]["culture"] === 2172 ? "Pois protéagineux de printemps" :  dataPodiumCA_2050_2D["3"][0]["culture"] === 2173 ?  "Pois protéagineux d'hiver" :  ''} 
              </div>
          </div>
        </div>
        <div className='row_tableau_prix_rapport'>
          <div className='data_tableau_podium_rapport color-tab-podium-50-2D' style={{height:'30px'}}> {dataPodiumCA_2050_2D["1"][0]["perte"]} % </div>
          <div className='data_tableau_podium_rapport color-tab-podium-50-2D' style={{height:'30px'}}> {dataPodiumCA_2050_2D['2']!== undefined ?dataPodiumCA_2050_2D["2"][0]["perte"]: "/"} % </div>
          <div className='data_tableau_podium_rapport color-tab-podium-50-2D' style={{height:'30px'}}> {dataPodiumCA_2050_2D["3"] !== undefined ? dataPodiumCA_2050_2D["3"][0]["perte"] : "/"} % </div>
        </div>
      </div>
      : ""}

      {dataPodiumCA_2050_4D !== undefined ?
      <div className='tableau_podium_rapport'> 
        <div className='row_tableau_prix_rapport'>
          <div className='data_tableau_podium_rapport  color-tab-podium-50-4D' style={{width:'99.3%',height:'30px' ,fontWeight:'bold'}}>2050  avec un réchauffement à +4°C </div>
        </div>
        <div className='row_tableau_prix_rapport'>
          <div className='data_tableau_podium_rapport_entete'> <img src={podium1} className='imageClassementPodium' alt="première place" /> </div>
          <div className='data_tableau_podium_rapport_entete'> <img src={podium2} className='imageClassementPodium' alt="deuxième place" /> </div>
          <div className='data_tableau_podium_rapport_entete'> <img src={podium3} className='imageClassementPodium' alt="troisème place" /> </div>
        </div>
        <div className='row_tableau_prix_rapport'>
          <div className='data_tableau_podium_rapport_entete'> 
            <div className='row'>
                <img src={dataPodiumCA_2050_4D["1"][0]["alea"] === 'SH' ? SH_ac :dataPodiumCA_2050_4D["1"][0]["alea"] === 'EE' ? EE_ac :dataPodiumCA_2050_4D["1"][0]["alea"] === 'CDC' ? CDC_ac :dataPodiumCA_2050_4D["1"][0]["alea"] === 'GEL' ? GEL_ac :''} alt='icone'  className="imageAleaPodiumRapport" />
                <img src={dataPodiumCA_2050_4D["1"][0]["culture"] === 1112 ||  dataPodiumCA_2050_4D["1"][0]["culture"] === 1113 ? BleT : dataPodiumCA_2050_4D["1"][0]["culture"] === 1122 ||  dataPodiumCA_2050_4D["1"][0]["culture"] === 1123 ? BleD : dataPodiumCA_2050_4D["1"][0]["culture"] === 114 ||  dataPodiumCA_2050_4D["1"][0]["culture"] === 115 ? Orge : dataPodiumCA_2050_4D["1"][0]["culture"] === 118 ? Mais : dataPodiumCA_2050_4D["1"][0]["culture"] === 222 ? Colza : (dataPodiumCA_2050_4D["1"][0]["culture"] === 630 || dataPodiumCA_2050_4D["1"][0]["culture"] === 670 || dataPodiumCA_2050_4D["1"][0]["culture"] === 675)? Viti : dataPodiumCA_2050_4D["1"][0]["culture"] === 221 ? Tournesol : dataPodiumCA_2050_4D["1"][0]["culture"] === 213 ? Betterave : dataPodiumCA_2050_4D["1"][0]["culture"] === 202 ? PdT : dataPodiumCA_2050_4D["1"][0]["culture"] === 323 ? Luzerne : dataPodiumCA_2050_4D["1"][0]["culture"] === 231 ? Chanvre : dataPodiumCA_2050_4D["1"][0]["culture"] === 223 ? Soja : dataPodiumCA_2050_4D["1"][0]["culture"] === 2142 ||  dataPodiumCA_2050_4D["1"][0]["culture"] === 2143 ? Feverole : dataPodiumCA_2050_4D["1"][0]["culture"] === 2172 ||  dataPodiumCA_2050_4D["1"][0]["culture"] === 2173 ? Pois :  ''} alt='icone'  className="imageAleaPodiumRapport" />
            </div> 
          </div>
          <div className='data_tableau_podium_rapport_entete'> 
            <div className='row'>
                <img src={dataPodiumCA_2050_4D["2"][0]["alea"] === 'SH' ? SH_ac :dataPodiumCA_2050_4D["2"][0]["alea"] === 'EE' ? EE_ac :dataPodiumCA_2050_4D["2"][0]["alea"] === 'CDC' ? CDC_ac :dataPodiumCA_2050_4D["2"][0]["alea"] === 'GEL' ? GEL_ac :''} alt='icone'  className="imageAleaPodiumRapport" />
                <img src={dataPodiumCA_2050_4D["2"][0]["culture"] === 1112 ||  dataPodiumCA_2050_4D["2"][0]["culture"] === 1113 ? BleT : dataPodiumCA_2050_4D["2"][0]["culture"] === 1122 ||  dataPodiumCA_2050_4D["2"][0]["culture"] === 1123 ? BleD : dataPodiumCA_2050_4D["2"][0]["culture"] === 114 ||  dataPodiumCA_2050_4D["2"][0]["culture"] === 115 ? Orge : dataPodiumCA_2050_4D["2"][0]["culture"] === 118 ? Mais : dataPodiumCA_2050_4D["2"][0]["culture"] === 222 ? Colza : (dataPodiumCA_2050_4D["2"][0]["culture"] === 630 || dataPodiumCA_2050_4D["2"][0]["culture"] === 670 || dataPodiumCA_2050_4D["2"][0]["culture"] === 675)? Viti : dataPodiumCA_2050_4D["2"][0]["culture"] === 221 ? Tournesol : dataPodiumCA_2050_4D["2"][0]["culture"] === 213 ? Betterave : dataPodiumCA_2050_4D["2"][0]["culture"] === 202 ? PdT : dataPodiumCA_2050_4D["2"][0]["culture"] === 323 ? Luzerne : dataPodiumCA_2050_4D["2"][0]["culture"] === 231 ? Chanvre : dataPodiumCA_2050_4D["2"][0]["culture"] === 223 ? Soja : dataPodiumCA_2050_4D["2"][0]["culture"] === 2142 ||  dataPodiumCA_2050_4D["2"][0]["culture"] === 2143 ? Feverole : dataPodiumCA_2050_4D["2"][0]["culture"] === 2172 ||  dataPodiumCA_2050_4D["2"][0]["culture"] === 2173 ? Pois :  ''} alt='icone'  className="imageAleaPodiumRapport" />
            </div> 
          </div>
          <div className='data_tableau_podium_rapport_entete'> 
            <div className='row'>
                <img src={dataPodiumCA_2050_4D["3"][0]["alea"] === 'SH' ? SH_ac :dataPodiumCA_2050_4D["3"][0]["alea"] === 'EE' ? EE_ac :dataPodiumCA_2050_4D["3"][0]["alea"] === 'CDC' ? CDC_ac :dataPodiumCA_2050_4D["3"][0]["alea"] === 'GEL' ? GEL_ac :''} alt='icone'  className="imageAleaPodiumRapport" />
                <img src={dataPodiumCA_2050_4D["3"][0]["culture"] === 1112 ||  dataPodiumCA_2050_4D["3"][0]["culture"] === 1113 ? BleT : dataPodiumCA_2050_4D["3"][0]["culture"] === 1122 ||  dataPodiumCA_2050_4D["3"][0]["culture"] === 1123 ? BleD : dataPodiumCA_2050_4D["3"][0]["culture"] === 114 ||  dataPodiumCA_2050_4D["3"][0]["culture"] === 115 ? Orge : dataPodiumCA_2050_4D["3"][0]["culture"] === 118 ? Mais : dataPodiumCA_2050_4D["3"][0]["culture"] === 222 ? Colza : (dataPodiumCA_2050_4D["3"][0]["culture"] === 630 || dataPodiumCA_2050_4D["3"][0]["culture"] === 670 || dataPodiumCA_2050_4D["3"][0]["culture"] === 675)? Viti : dataPodiumCA_2050_4D["3"][0]["culture"] === 221 ? Tournesol : dataPodiumCA_2050_4D["3"][0]["culture"] === 213 ? Betterave : dataPodiumCA_2050_4D["3"][0]["culture"] === 202 ? PdT : dataPodiumCA_2050_4D["3"][0]["culture"] === 323 ? Luzerne : dataPodiumCA_2050_4D["3"][0]["culture"] === 231 ? Chanvre : dataPodiumCA_2050_4D["3"][0]["culture"] === 223 ? Soja : dataPodiumCA_2050_4D["3"][0]["culture"] === 2142 ||  dataPodiumCA_2050_4D["3"][0]["culture"] === 2143 ? Feverole : dataPodiumCA_2050_4D["3"][0]["culture"] === 2172 ||  dataPodiumCA_2050_4D["3"][0]["culture"] === 2173 ? Pois :  ''} alt='icone'  className="imageAleaPodiumRapport" />
            </div> 
          </div>
        </div>
        <div className='row_tableau_prix_rapport'>
          <div className='data_tableau_podium_rapport' style={{height:'60px'}}> 
            <div style={{textAlign:'center'}}> 
              {dataPodiumCA_2050_4D["1"][0]["alea"] === 'SH' ? "Stress hydrique" : dataPodiumCA_2050_4D["1"][0]["alea"] === 'EE' ? "Excès d'eau" : dataPodiumCA_2050_4D["1"][0]["alea"] === 'CDC' ? 'Coup de chaleur' : dataPodiumCA_2050_4D["1"][0]["alea"] === 'GEL' ? "Gel" : ''} / 
              {dataPodiumCA_2050_4D["1"][0]["culture"] === 1112 ? "Blé tendre de printemps" : dataPodiumCA_2050_4D["1"][0]["culture"] === 1113 ? "Blé tendre d'hiver" : dataPodiumCA_2050_4D["1"][0]["culture"] === 1122 ? "Blé dur de printemps" :  dataPodiumCA_2050_4D["1"][0]["culture"] === 1123 ? "Blé dur d'hiver" : dataPodiumCA_2050_4D["1"][0]["culture"] === 114 ? "Orge de printemps"  : dataPodiumCA_2050_4D["1"][0]["culture"] === 115 ? "Orge d'hiver" : dataPodiumCA_2050_4D["1"][0]["culture"] === 118 ? "Maïs" : dataPodiumCA_2050_4D["1"][0]["culture"] === 222 ? "Colza" : dataPodiumCA_2050_4D["1"][0]["culture"] === 630 ? "Viticulture AOC" : dataPodiumCA_2050_4D["1"][0]["culture"] === 670 ? "Viticulture IGP" : dataPodiumCA_2050_4D["1"][0]["culture"] === 675 ? "Viticulture VSIG" : dataPodiumCA_2050_4D["1"][0]["culture"] === 221 ? "Tournesol" : dataPodiumCA_2050_4D["1"][0]["culture"] === 213 ? "Betterave sucrière" : dataPodiumCA_2050_4D["1"][0]["culture"] === 202 ? "Pomme de terre" : dataPodiumCA_2050_4D["1"][0]["culture"] === 323 ? "Luzerne" : dataPodiumCA_2050_4D["1"][0]["culture"] === 231 ? "Chanvre" : dataPodiumCA_2050_4D["1"][0]["culture"] === 223 ? "Soja" : dataPodiumCA_2050_4D["1"][0]["culture"] === 2142 ? "Feverole de printemps" :  dataPodiumCA_2050_4D["1"][0]["culture"] === 2143 ? "Feverole d'hiver" : dataPodiumCA_2050_4D["1"][0]["culture"] === 2172 ? "Pois protéagineux de printemps" :  dataPodiumCA_2050_4D["1"][0]["culture"] === 2173 ?  "Pois protéagineux d'hiver" :  ''} 
            </div>
          </div>
          <div className='data_tableau_podium_rapport' style={{height:'60px'}}> 
            <div style={{textAlign:'center'}}> 
              {dataPodiumCA_2050_4D["2"][0]["alea"] === 'SH' ? "Stress hydrique" : dataPodiumCA_2050_4D["2"][0]["alea"] === 'EE' ? "Excès d'eau" : dataPodiumCA_2050_4D["2"][0]["alea"] === 'CDC' ? 'Coup de chaleur' : dataPodiumCA_2050_4D["2"][0]["alea"] === 'GEL' ? "Gel" : ''} / 
              {dataPodiumCA_2050_4D["2"][0]["culture"] === 1112 ? "Blé tendre de printemps" : dataPodiumCA_2050_4D["2"][0]["culture"] === 1113 ? "Blé tendre d'hiver" : dataPodiumCA_2050_4D["2"][0]["culture"] === 1122 ? "Blé dur de printemps" :  dataPodiumCA_2050_4D["2"][0]["culture"] === 1123 ? "Blé dur d'hiver" : dataPodiumCA_2050_4D["2"][0]["culture"] === 114 ? "Orge de printemps"  : dataPodiumCA_2050_4D["2"][0]["culture"] === 115 ? "Orge d'hiver" : dataPodiumCA_2050_4D["2"][0]["culture"] === 118 ? "Maïs" : dataPodiumCA_2050_4D["2"][0]["culture"] === 222 ? "Colza" : dataPodiumCA_2050_4D["2"][0]["culture"] === 630 ? "Viticulture AOC" : dataPodiumCA_2050_4D["2"][0]["culture"] === 670 ? "Viticulture IGP" : dataPodiumCA_2050_4D["2"][0]["culture"] === 675 ? "Viticulture VSIG" : dataPodiumCA_2050_4D["2"][0]["culture"] === 221 ? "Tournesol" : dataPodiumCA_2050_4D["2"][0]["culture"] === 213 ? "Betterave sucrière" : dataPodiumCA_2050_4D["2"][0]["culture"] === 202 ? "Pomme de terre" : dataPodiumCA_2050_4D["2"][0]["culture"] === 323 ? "Luzerne" : dataPodiumCA_2050_4D["2"][0]["culture"] === 231 ? "Chanvre" : dataPodiumCA_2050_4D["2"][0]["culture"] === 223 ? "Soja" : dataPodiumCA_2050_4D["2"][0]["culture"] === 2142 ? "Feverole de printemps" :  dataPodiumCA_2050_4D["2"][0]["culture"] === 2143 ? "Feverole d'hiver" : dataPodiumCA_2050_4D["2"][0]["culture"] === 2172 ? "Pois protéagineux de printemps" :  dataPodiumCA_2050_4D["2"][0]["culture"] === 2173 ?  "Pois protéagineux d'hiver" :  ''} 
            </div>
          </div>
          <div className='data_tableau_podium_rapport' style={{height:'60px'}}> 
            <div style={{textAlign:'center'}}>  
              {dataPodiumCA_2050_4D["3"][0]["alea"] === 'SH' ? "Stress hydrique" : dataPodiumCA_2050_4D["3"][0]["alea"] === 'EE' ? "Excès d'eau" : dataPodiumCA_2050_4D["3"][0]["alea"] === 'CDC' ? 'Coup de chaleur' : dataPodiumCA_2050_4D["3"][0]["alea"] === 'GEL' ? "Gel" : ''} / 
              {dataPodiumCA_2050_4D["3"][0]["culture"] === 1112 ? "Blé tendre de printemps" : dataPodiumCA_2050_4D["3"][0]["culture"] === 1113 ? "Blé tendre d'hiver" : dataPodiumCA_2050_4D["3"][0]["culture"] === 1122 ? "Blé dur de printemps" :  dataPodiumCA_2050_4D["3"][0]["culture"] === 1123 ? "Blé dur d'hiver" : dataPodiumCA_2050_4D["3"][0]["culture"] === 114 ? "Orge de printemps"  : dataPodiumCA_2050_4D["3"][0]["culture"] === 115 ? "Orge d'hiver" : dataPodiumCA_2050_4D["3"][0]["culture"] === 118 ? "Maïs" : dataPodiumCA_2050_4D["3"][0]["culture"] === 222 ? "Colza" : dataPodiumCA_2050_4D["3"][0]["culture"] === 630 ? "Viticulture AOC" : dataPodiumCA_2050_4D["3"][0]["culture"] === 670 ? "Viticulture IGP" : dataPodiumCA_2050_4D["3"][0]["culture"] === 675 ? "Viticulture VSIG" : dataPodiumCA_2050_4D["3"][0]["culture"] === 221 ? "Tournesol" : dataPodiumCA_2050_4D["3"][0]["culture"] === 213 ? "Betterave sucrière" : dataPodiumCA_2050_4D["3"][0]["culture"] === 202 ? "Pomme de terre" : dataPodiumCA_2050_4D["3"][0]["culture"] === 323 ? "Luzerne" : dataPodiumCA_2050_4D["3"][0]["culture"] === 231 ? "Chanvre" : dataPodiumCA_2050_4D["3"][0]["culture"] === 223 ? "Soja" : dataPodiumCA_2050_4D["3"][0]["culture"] === 2142 ? "Feverole de printemps" :  dataPodiumCA_2050_4D["3"][0]["culture"] === 2143 ? "Feverole d'hiver" : dataPodiumCA_2050_4D["3"][0]["culture"] === 2172 ? "Pois protéagineux de printemps" :  dataPodiumCA_2050_4D["3"][0]["culture"] === 2173 ?  "Pois protéagineux d'hiver" :  ''} 
            </div>
          </div>
        </div>
        <div className='row_tableau_prix_rapport'>
          <div className='data_tableau_podium_rapport color-tab-podium-50-4D' style={{height:'30px'}}> {dataPodiumCA_2050_4D["1"][0]["perte"]} % </div>
          <div className='data_tableau_podium_rapport color-tab-podium-50-4D' style={{height:'30px'}}> {dataPodiumCA_2050_4D['2']!== undefined ?dataPodiumCA_2050_4D["2"][0]["perte"]: "/"} % </div>
          <div className='data_tableau_podium_rapport color-tab-podium-50-4D' style={{height:'30px'}}> {dataPodiumCA_2050_4D["3"] !== undefined ? dataPodiumCA_2050_4D["3"][0]["perte"] : "/"} % </div>
        </div>
      </div>
      : ""}
      
      
    </div>
  )
}

export default PageAFOM